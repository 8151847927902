const Chapter6 = [

    {
      "id": "chapter_6",
      "title": "Setting performance targets",
      subChapters: [
        {
        "id": "introduction_to_performance_targets_in_projects",
        "title": "Introduction to performance targets in projects ",
        "sections": [
          {
            "id": "Introduction_to_performance_targets_in_projects ",
            "title": "Introduction to performance targets in projects ",
            "content": `Performance targets are essential in project management as they provide a clear direction, set expectations, and enable effective monitoring and control throughout the project lifecycle. Properly defined performance targets help project teams focus on key objectives, ensure alignment with stakeholders, and ultimately contribute to the successful delivery of the project.  
 
 
In this chapter, we will explore the importance of setting performance targets, the activities involved, and guidelines for ensuring they are effective. Additionally, we have discussed about the role of scope, schedule, and cost baselines as critical performance targets for traditional approach projects. We have also discussed how Scope, Schedule, and Cost are managed in both traditional and agile project management approaches.
`,
          
          },

          {
            id : "Importance_of_Performance_Targets ",
            title : "Importance of Performance Targets ",
            content : `1	Clear Objectives: Performance targets translate the project’s goals and objectives into specific, measurable outcomes. By establishing these targets, project managers can clearly communicate what success looks like, helping the team stay focused and motivated. 
2	Alignment with Stakeholders: Setting performance targets ensures that all stakeholders have a shared understanding of the project’s goals. This alignment is crucial for securing buy-in and support throughout the project’s duration. 
3	Measuring Progress: With well-defined performance targets, project managers can regularly assess progress and identify any deviations from the plan. This enables timely corrective actions and ensures the project stays on track. 
4	Accountability: Performance targets establish accountability within the project team. Each member understands their responsibilities and the specific targets they are expected to achieve, fostering a sense of ownership and commitment. 
 
**Scope, Schedule, and Cost Baselines as Performance Targets** 
 
In project management, three fundamental baselines serve as critical performance measurement baselines: scope, schedule, and cost. These baselines are interdependent and form the foundation of the project plan. 
 
1	Scope Baseline: The scope baseline defines the project's deliverables and the work required to complete them. It serves as a performance target by establishing what is included in the project and, equally important, what is not. Managing scope effectively ensures that the project team remains focused on delivering the agreedupon outcomes without unnecessary deviations or scope creep. 
 
2	Schedule Baseline: The schedule baseline is the approved project timeline, outlining the start and finish dates for the project’s activities. It serves as a performance target by setting expectations for when each task or milestone should be completed. Monitoring performance against the schedule baseline helps ensure that the project progresses according to plan and that any delays are promptly addressed. 
 
3	Cost Baseline: The cost baseline represents the approved budget for the project. It serves as a performance target by defining the financial resources allocated to each phase or activity. Effective cost management involves tracking expenditures against the cost baseline to ensure that the project remains within budget and that financial resources are used efficiently. 
 
 
 	 
**Managing Scope, Schedule, and Cost in Traditional and Agile Approaches** 
 
The approach to managing scope, schedule, and cost baselines can vary significantly between traditional (waterfall) and agile project management methodologies. 
Understanding these differences is key to setting realistic performance targets and ensuring project success. 
 
Traditional Project Management Approach 
In traditional project management, often referred to as the waterfall model, scope, schedule, and cost baselines are typically defined during the planning phase and remain relatively fixed throughout the project. This approach emphasizes thorough upfront planning and detailed documentation. 
 
•	Scope Management: The scope is carefully defined and documented at the beginning of the project. Changes to the scope require formal change control procedures, which may involve re-evaluating the project’s schedule and cost baselines. 
•	Schedule Management: A detailed project schedule is developed during the planning phase, with all tasks and milestones mapped out. The project manager monitors progress against this schedule and takes corrective actions if deviations occur. 
•	Cost Management: The project budget is established based on detailed cost estimates. Costs are closely monitored, and any variances from the cost baseline are addressed through formal change control processes. 
`
          },

          {
            id : "Agile_Project_Management_Approach ",
            title : "Agile Project Management Approach ",
            content : `In contrast, agile project management is characterized by its flexibility and iterative nature. Scope, schedule, and cost baselines are more fluid, allowing for adjustments based on ongoing feedback and changing requirements. 
 
•	Scope Management: In agile, scope is defined at a high level and refined incrementally through user stories or backlog items. The scope baseline is more flexible, allowing for changes based on customer feedback and evolving project needs. 
•	Schedule Management: Agile projects use iterative cycles (sprints) to manage the schedule. Instead of a fixed timeline, the schedule is continuously adjusted based on the outcomes of each sprint. Performance targets are set for each sprint, focusing on delivering specific features or increments. 
•	Cost Management: Agile projects often work within a fixed budget but allow for adjustments in scope and schedule to accommodate cost constraints. Cost management is focused on delivering the maximum value within the available budget, often through prioritizing features or user stories. 
 
In the upcoming lessons of this chapter, we will discuss these areas of project management in detail to help you learn the best practices of managing scope, schedule, & cost in projects.  
Guidelines for setting effective performance targets 
 
1	Ensure Relevance: Performance targets should be directly linked to the project’s strategic objectives. Avoid setting targets that do not contribute to the overall success of the project. 
 
2	Be Realistic: While it’s important to be ambitious, targets should also be achievable. Overly aggressive targets can lead to stress, burnout, and decreased morale. 
 
3	Focus on Quality: Performance targets should emphasize not only the completion of tasks but also the quality of the outcomes. Quality should never be compromised for the sake of meeting deadlines or cost targets. 
 
4	Incorporate Flexibility: The project environment can be dynamic, with changes occurring due to external factors, stakeholder needs, or resource availability. Performance targets should be flexible enough to accommodate such changes without derailing the project. 
 
5	Use Technology: Leverage project management tools and software to track performance against targets. These tools can provide real-time data, facilitate communication, and support decision-making processes. 
 
6	Continuous Improvement: Encourage a culture of continuous improvement within the project team. Regularly review and refine performance targets based on lessons learned and feedback from stakeholders. 
 
 
Setting performance targets is a critical component of successful project management. By establishing clear, measurable, and achievable targets for scope, schedule, and cost baselines, project managers can guide their teams toward the desired outcomes, ensure stakeholder alignment, and maintain control over the project’s progress. Understanding the differences in managing these baselines between traditional and agile approaches can help project managers tailor their strategies to fit the project's needs and enhance the likelihood of success. 
`,
          },

         
        ]
    },

    {
        "id": "defining_scope_of_the_project",
        "title": "Defining Scope of the project (Traditional)",
        "sections": [
          {
            "id": "Defining_Scope_of_the_project_(Traditional)",
            "title": "Defining Scope of the project (Traditional)",
            "content": `Planning the scope of a project is a crucial step in project management that involves defining the boundaries, deliverables, and objectives of the project. It establishes the framework within which the project will be executed, enabling stakeholders to have a clear understanding of what will be included and excluded from the project. Effective scope planning sets the stage for project success by providing a roadmap for the project team and ensuring alignment with stakeholder expectations. 
 
Here are the key steps involved in planning the scope of a project: 
 
Project Objectives: Start by clearly defining the project objectives and goals. These objectives should be specific, measurable, attainable, relevant, and time-bound (SMART). Objectives provide a clear direction and purpose for the project. 
 
Stakeholder Analysis: Identify and engage with key stakeholders who have an interest in or will be affected by the project. Understand their expectations, requirements, and concerns. Stakeholder input is crucial in determining the project's scope and ensuring alignment with their needs. 
 
Requirements Gathering: Gather detailed requirements by collaborating with stakeholders and subject matter experts. Identify the specific features, functionalities, and characteristics desired in the project deliverables. Document the requirements to serve as a foundation for defining the project scope. 
 
Scope Definition: Based on the gathered requirements, define the scope of the project. This involves determining the project's boundaries, deliverables, and exclusions. The scope statement should clearly articulate what work will be performed, the desired outcomes, and what will not be included in the project. 
 
Work Breakdown Structure (WBS): Create a work breakdown structure (WBS) to decompose the project into smaller, manageable components. The WBS organizes the project's work into hierarchical levels, breaking it down into tasks, sub-tasks, and work packages. The WBS provides a visual representation of the project's scope and forms the basis for subsequent planning activities. 
 
Scope Verification: Validate the defined scope with stakeholders to ensure their agreement and understanding. Review the scope statement and WBS with key stakeholders to confirm that it accurately represents their expectations. Scope verification helps in reducing misunderstandings and ensures that the project team and stakeholders are aligned on the project's scope. 
 
Scope Change Management: Establish a process to manage scope changes. As the project progresses, there may be requests to modify the project scope due to evolving needs or new insights. Implement a change control process to evaluate, approve, or reject scope changes systematically. This helps in maintaining project focus and controlling scope creep. 
 
Scope Communication: Clearly communicate the project's scope to all stakeholders involved. This includes project team members, clients, sponsors, and any other relevant parties. Effective communication ensures a shared understanding of the project's boundaries, deliverables, and constraints. 
 
Scope Documentation: Document the project scope in a formal document, such as the project management plan or scope statement. The scope documentation serves as a reference throughout the project lifecycle, enabling project managers and team members to stay aligned and on track. 
 
Ongoing Scope Monitoring: Continuously monitor the project's scope during execution. Track and assess any potential scope changes or deviations. Regularly review and update the scope documentation as needed. This ensures that the project remains within the defined boundaries and that any scope changes are properly managed. 
 
By following these steps in planning the scope of a project, project managers can establish a clear understanding of the project's boundaries, deliverables, and objectives. It helps in preventing scope creep*, managing stakeholder expectations, and ensuring that the project remains focused and on track for success.  
 	 
According to Process group practice guide released by PMI®, Planning in scope includes 4 Project Management processes as indicated in the planning section of the image below: 
 
`,
image : "/docs/images/monitoringandcontrolling.png"
          },

          {
            id : "Plan_scope_management ",
            title : "Plan scope management ",
            content : `In the world of project management, the ability to define and control what is included—and equally important, what is not included—in a project is crucial to its success. This process is known as scope management, and it begins with a key component: the Plan Scope Management process. This article will explore what Plan Scope Management is, why it is essential, and how to effectively implement it in your projects. 
 
Plan Scope Management is the process that outlines how the scope of a project will be defined, validated, and controlled. It provides a roadmap for managing the scope throughout the project lifecycle, ensuring that all project deliverables are clearly defined and that the project remains on track. The output of this process is the Scope Management Plan, a critical document that guides the project team and stakeholders in managing scoperelated activities. 
 
The Importance of the process: Plan Scope Management 
 
1	Clarity and Focus: The Plan Scope Management process provides clarity on the project's boundaries, ensuring that all stakeholders have a shared understanding of what the project will deliver. This helps in maintaining focus on the project's objectives and prevents unnecessary work or scope creep.

2	Alignment with Stakeholders: By clearly defining how the scope will be managed, the process ensures that all stakeholders are aligned on the project’s goals, requirements, and deliverables. This alignment is essential for securing stakeholder buy-in and support throughout the project.

3	Effective Change Management: A well-defined Scope Management Plan includes procedures for handling scope changes. This ensures that any changes are properly evaluated, approved, and integrated into the project plan, minimizing the risk of scope creep and ensuring that changes do not derail the project.

4	Control Over Project Deliverables: By establishing a clear plan for scope management, project managers can better control the project’s deliverables, ensuring that they meet the required standards and are delivered within the agreedupon time and budget. 
 
Key Components of a Scope Management Plan 
The Scope Management Plan is the primary output of the Plan Scope Management process.  
 
It typically includes the following components: 
 
1	Process for Collecting Requirements: This section outlines how the project team will gather requirements from stakeholders. It defines the techniques and tools to be used, such as interviews, surveys, or workshops, and identifies the key stakeholders involved in the process. 

2	Scope Statement: The scope statement provides a detailed description of the project’s deliverables and the work required to produce them. It serves as a reference point for all scope-related decisions throughout the project. 

3	Work Breakdown Structure (WBS): The WBS is a hierarchical decomposition of the project’s scope into smaller, manageable components. The Scope Management Plan should define how the WBS will be developed, maintained, and used throughout the project. 

4	Scope validation/verification Process: This section describes the process for verifying that the project’s deliverables meet the defined requirements. It includes the criteria for acceptance and the procedures for formalizing stakeholder approval. 

5	Scope Control Process: The scope control process outlines how changes to the project scope will be managed. It includes the procedures for identifying, evaluating, and approving changes, as well as the tools and techniques to be used for tracking scope performance. 
 
 	 
**Guidelines for Plan Scope Management** 
 
1	Engage Stakeholders Early and Often: Effective scope management starts with early and continuous stakeholder engagement. Involve key stakeholders in defining the scope and developing the Scope Management Plan to ensure their buy-in and support. 

2	Be Clear and Specific: Ambiguity in scope definitions can lead to misunderstandings and scope creep. Be as clear and specific as possible when defining the project’s scope and requirements. 

3	Use Visual Tools: Tools such as mind maps, flowcharts, and WBS diagrams can help visually represent the project’s scope and make it easier for stakeholders to understand and agree upon. 

4	Regularly Review and Update the Plan: The Scope Management Plan should not be a static document. Regularly review and update the plan to reflect changes in the project environment, stakeholder expectations, or other factors.

5	Document Everything: Thorough documentation is key to effective scope management. Ensure that all scope-related decisions, changes, and approvals are well-documented and accessible to the project team. 
Plan Scope Management is a foundational process in project management that sets the stage for successful scope definition, validation, and control. By developing a comprehensive Scope Management Plan, project managers can ensure that their projects stay on track, deliver the expected outcomes, and satisfy stakeholder expectations. Following best practices for Plan Scope Management will help you manage scope effectively and minimize the risk of scope creep, ultimately leading to a more successful project. 
`,
image :"/docs/images/monitoringandcontrolling2.png",
          },

          {
            id : "Collect_Requirements ",
            title : "Collect Requirements ",
            content : `The "Collect Requirements" process is a part of the project scope management knowledge area. It focuses on gathering and documenting the project requirements from stakeholders to ensure a comprehensive understanding of their needs and expectations. 
 
**What are requirements?** 
 
Requirements are statements that define the needs, expectations, and constraints of a project or system. They describe what a project or system should do, how it should behave, and the criteria by which it will be judged successful. Requirements serve as the foundation for designing, developing, and delivering the desired solution. 
 
Requirements in project consists of both Project and Product requirements, Let's clarify the distinction between the two: 
 	 
**Product Requirements** 
 
Product requirements specify the features, functions, and characteristics of the product or system being developed. These requirements outline what the product should do and how it should behave to meet the needs of its intended users or customers. Product requirements are focused on the characteristics and functionalities of the final deliverable. Examples of product requirements: 
 
"The software application shall have a user-friendly interface with intuitive navigation." 
"The website shall support multiple languages to cater to an international audience." "The mobile app shall provide offline access to content for users in areas with limited connectivity." 
 
Product requirements can be further categorized into two main types: 
 
Functional Requirements: Functional requirements specify the specific functions, features, and capabilities that the project or system should possess. They describe the desired behavior and interactions with the system. Functional requirements answer the question, "What should the system do?"  
 
Examples of functional requirements include: 
 
"The system shall allow users to create and edit customer profiles." 
"The system shall generate monthly sales reports." 
"The system shall provide a search functionality to retrieve relevant documents." 
 
Non-functional Requirements: Non-functional requirements focus on qualities and constraints that are not directly related to specific system functionalities but define how the system should perform. They encompass aspects such as performance, reliability, security, usability, scalability, and more. Non-functional requirements answer the question, "How should the system perform?"  
 
Examples of non-functional requirements include: 
 
"The system shall respond to user queries within two seconds." 
"The system shall maintain 99.99% uptime." 
"The system shall encrypt all sensitive user data."  
 	 
**Project Requirements** 
 
Project requirements, on the other hand, pertain to the specific needs and constraints associated with the project's execution. They define what is required to successfully plan, execute, and complete the project itself. Project requirements encompass factors such as timelines, resources, budget, and project management methodologies. Examples of project requirements: 
 
"The project should be completed within six months from the start date." 
"The project team must consist of at least two developers and one quality assurance specialist." 
"The project must adhere to the Agile project management framework." 
Project requirements are focused on the management and execution aspects of the project, rather than the functionalities and characteristics of the product. 
 
It's essential to capture and manage both product requirements and project requirements to ensure successful project delivery. The product requirements define the features and functionalities expected from the product, while the project requirements outline the constraints and parameters necessary for the project's successful execution.  
 
Scope Creep: Scope creep refers to the gradual and unauthorized expansion of a project's goals, requirements, or features beyond its initial boundaries or agreed-upon scope, leading to increased costs, delays, and potential quality issues. It often occurs when additional functionalities or changes are introduced without proper assessment or control.  
 
In Projects, the Project Manager and team need to collect and create a comprehensive list of requirements, which will help define the Project Scope. Let’s have a look at the Collect requirements process as defined in Process group practice guide by PMI® 
`,
          },

          {
            id  : "Data_gathering_techniques ",
            title : "Data gathering techniques ",
            content : `There are multiple techniques that can be used in a project to collect requirements, these techniques can be classified as “Data Gathering Techniques”. The Project Manager and the team will decide which of these techniques will be most suitable given the uniqueness of their project. Let’s have a look at these techniques as shown in image and more: `,
            image : "/docs/images/datagathering.png"
          },
          {
            content : `When gathering requirements for a project, it's important to employ various techniques to ensure a comprehensive understanding of the needs and expectations. Here are some data gathering techniques commonly used for collecting requirements: 
 
Interviews: Conducting interviews with stakeholders, subject matter experts, and potential users is an effective way to gather requirements. It allows direct interaction, providing an opportunity to ask specific questions and seek clarification. For example, interviewing managers, end users, or customer support representatives can provide valuable insights into their needs and pain points. This technique is suitable when you need detailed information from key individuals who have specific knowledge or experience related to the project. 
 
Surveys and Questionnaires: Distributing surveys or questionnaires to a wide range of individuals or groups can help gather requirements from a larger population. This method is particularly useful when there are numerous stakeholders or potential users. Examples include online surveys sent to customers to collect feedback on their preferences or expectations. Surveys and questionnaires are suitable when you need to collect quantitative or qualitative data from a large audience and want to gather a broad range of opinions or preferences. 
 
 
 
Workshops and Focus Groups: Organizing workshops or focus groups brings together stakeholders and facilitates group discussions. This technique encourages collaboration, brainstorming, and the exchange of ideas. For instance, a requirements workshop can involve stakeholders from different departments to gather diverse perspectives and identify common needs. Workshops and focus groups are suitable when you want to foster active participation, generate ideas collectively, and build consensus among stakeholders. 
 
Observation: Directly observing users or stakeholders in their natural environment provides valuable insights into their behaviors, tasks, and challenges. This technique is especially useful for understanding workflows and user interactions. For example, observing employees in a call center to identify pain points and areas for improvement. Observation is suitable when you want to gain a deep understanding of how people currently perform tasks, identify pain points, or discover implicit requirements that may not be explicitly stated. 
 
Document Analysis: Reviewing existing documentation such as business processes, user manuals, and technical specifications can uncover valuable information. It helps in understanding the current system or identifying gaps that need to be addressed. For instance, analyzing customer feedback logs to identify recurring issues and requirements for a software upgrade. Document analysis is suitable when you want to gather information from existing sources, validate requirements against documented processes, or identify areas for improvement. 
 
Prototyping and Mockups: Creating prototypes or mockups allows stakeholders and users to visualize the proposed system or product. It helps gather feedback early in the process and validates requirements. For example, developing a clickable prototype of a website to gather user feedback on its usability and interface. Prototyping and mockups are suitable when you want to gather feedback on the user experience, test feasibility, and validate requirements in a tangible way. 
 
Benchmarking and Best Practices: Researching and studying similar projects or industry best practices can provide valuable insights and inspiration for gathering requirements. It helps in identifying features, functionalities, or processes that are widely accepted and successful in similar contexts. For example, analyzing competitor products to understand market expectations and gather feature ideas. Benchmarking and best practices research are suitable when you want to gather ideas, learn from successful examples, and identify industry standards or norms. 
 
Social Media and Online Communities: Monitoring social media platforms, online forums, and user communities can provide real-time feedback and insights from a broader audience. It allows for understanding user preferences, concerns, and emerging trends. For instance, analyzing user discussions on an online forum dedicated to a particular product or service to identify potential enhancements or feature requests. Social media and online communities are suitable when you want to tap into a wider audience, gather user opinions and sentiments, or stay updated with the latest trends and discussions. 
 
By combining these data gathering techniques, project teams can gather comprehensive requirements that consider the needs of stakeholders, users, and industry standards. The selection of techniques should be based on the project's context, the availability of resources, and the desired depth of understanding required. It may also be beneficial to use a mix of techniques to gather a variety of perspectives and insights. 
 
 
Data gathering is not limited only to collecting requirements but also an integral part of various other areas in project management.  
 
(Before looking at the below list, try to create your own list of areas in which you and your team would need to gather data.) 
 
Here are some key areas where data gathering is commonly applied: 
 
Project Initiation: Data gathering is essential during the initiation phase to gather information about the project's objectives, scope, and initial requirements. Techniques like interviews, surveys, and document analysis are used to collect data from stakeholders, subject matter experts, and relevant documents. 
 
Project Planning: Data gathering plays a vital role in project planning activities. Techniques like interviews, workshops, and brainstorming sessions are used to define project scope, identify risks, estimate resources, and develop project schedules. Data gathering also supports the analysis of historical project data and best practices. 
 
Risk Management: Data gathering techniques such as interviews, workshops, and expert judgment are used to identify, assess, and prioritize project risks. Gathering data about potential risks and their impact helps in developing risk mitigation strategies and contingency plans. 
 
Stakeholder Management: Data gathering techniques like interviews, surveys, and focus groups are employed to gather information about stakeholders' needs, expectations, and concerns. This data helps in understanding stakeholder perspectives, analyzing their influence, and developing effective stakeholder management strategies. 
 
Communication Management: Data gathering is crucial for effective communication management. Techniques like interviews, meetings, and surveys help in collecting information about communication preferences, channels, and frequencies. This data is used to develop communication plans and ensure the right information reaches the relevant stakeholders. 
 
Quality Management: Data gathering techniques such as inspections, audits, and reviews are used to collect data on project deliverables, processes, and adherence to quality standards. This data is analyzed to ensure that project outputs meet the defined quality criteria and identify areas for improvement. 
 
Monitoring and Control: Data gathering plays a significant role in monitoring project progress, performance, and adherence to project plans. Techniques like progress meetings, data analysis, and reporting help in collecting data on project milestones, schedule variance, cost performance, and other key performance indicators. This data supports effective project control and decision-making. 
 
Lessons Learned: Data gathering techniques such as interviews, workshops, and postproject reviews are used to gather data for lessons learned. This involves collecting information about project successes, failures, and best practices. Data gathered in this area helps in documenting valuable project experiences and improving future project performance. 
 
The specific techniques and methods employed depend on the project's nature, complexity, and available resources, as well as the desired depth of understanding required for effective project management. 
 
The primary output of the "Collect Requirements" process is the "Requirements Documentation." It includes a comprehensive and detailed list of project requirements gathered from stakeholders. The requirements documentation can take various forms, such as a requirements traceability matrix or requirements specification document. 
 
Let’s understand more about requirements documentation:  
 
 
 	 
**Requirements documentation** 
 
Requirements documentation serves as a crucial reference and communication tool throughout the project lifecycle. It helps ensure a clear understanding of the project's objectives, scope, and expectations among stakeholders, project team members, and other relevant parties. The primary purpose of requirements documentation is to provide a detailed description of what the project or system needs to achieve and how it will meet the needs of its users. 
 
Key elements typically included in requirements documentation may vary based on the project and organization's specific requirements management approach. However, common components often found in requirements documentation include: 
 
Introduction: This section provides an overview of the project and its context, including the purpose, scope, and objectives. 
 
Stakeholder Requirements: It outlines the needs, expectations, and goals of the project stakeholders, including end-users, customers, and other key parties. This section focuses on capturing high-level requirements from the stakeholders' perspective. 
 
Functional Requirements: These specify the specific functions, features, and capabilities that the project or system should possess. Functional requirements describe the behavior, inputs, outputs, and interactions with the system. 
 
Non-functional Requirements: Non-functional requirements cover aspects such as performance, reliability, security, usability, scalability, and other qualities that are not directly related to specific system functionalities. 
 
System Constraints: This section outlines any limitations or restrictions that may affect the project, such as technical, budgetary, or regulatory constraints. 
 
Acceptance Criteria: Acceptance criteria define the conditions that must be met for each requirement to be considered successfully implemented and accepted by the stakeholders. 
 
Requirements Traceability Matrix: A traceability matrix establishes links between the requirements and other project artifacts, such as design documents, test cases, and deliverables, ensuring that each requirement is accounted for throughout the project lifecycle. 
 
Requirements documentation serves as a baseline for managing changes, conducting impact analysis, and ensuring that the final deliverables meet the stakeholders' expectations. It is an essential document that guides the project team's activities, including design, development, testing, and validation processes. 
 
 
Overall, by effectively collecting requirements, project managers and teams can ensure a clear understanding of stakeholders' needs and expectations, which enables them to develop and deliver a product or solution that meets those requirements and achieves project success. Carrying out the process of Collecting Requirements successfully is critical in project’s success.  
 
Once the requirements are gathered, the next step will be to verify these requirements to resolve any competing demands and balance stakeholders’ needs. 
 
 
 	  
**Requirements Review** 
 
Requirements review is a critical activity in which the collected requirements are evaluated, analyzed, and validated to ensure their quality, completeness, and alignment with stakeholders' needs. It involves reviewing the requirements to resolve competing demands and strike a balance between different stakeholders' needs and expectations. The primary goal of the requirements review is to ensure that the requirements are clear, consistent, and feasible before proceeding with further project activities. 
 
Here's an explanation of how requirements review helps in resolving competing demands and balancing stakeholders' needs: 
 
Identify Conflicts: During the requirements review, the collected requirements are carefully analyzed to identify any conflicts or inconsistencies among different stakeholders' demands. Conflicts can arise when different stakeholders have varying priorities, objectives, or expectations. The review process helps in surfacing these conflicts, making them visible for resolution. 
 
Prioritize Requirements: Once conflicts are identified, the requirements review allows for prioritizing the requirements based on their importance and impact. By considering the value and benefits associated with each requirement, project teams can determine which requirements should be given higher priority. This prioritization helps in balancing stakeholders' needs by addressing critical requirements and managing trade-offs. 
 
Facilitate Collaboration: Requirements review involves engaging stakeholders, including subject matter experts, users, and decision-makers, in the evaluation process. By bringing these stakeholders together, the review process promotes collaboration, discussion, and negotiation. This collaborative environment allows stakeholders to express their perspectives, understand each other's needs, and work towards finding common ground. 
 
Seek Consensus: The requirements review process aims to reach a consensus among stakeholders regarding the requirements. Through discussion, clarification, and compromise, stakeholders can align their expectations and find mutually agreeable solutions. Consensus-building ensures that the requirements reflect a balanced representation of stakeholders' needs and interests. 
 
Validate Feasibility: Requirements review also assesses the feasibility of the collected requirements. It involves evaluating whether the requirements can be realistically achieved within the project's constraints, such as time, budget, technology, and resources. If certain requirements are deemed unfeasible, alternatives or modifications can be explored to strike a balance between stakeholders' needs and project limitations. 
 
 	 
Document Changes: Throughout the requirements review process, any changes, refinements, or resolutions are documented. This includes updating the requirements documentation, traceability matrices, and any other relevant project artifacts. Clear documentation ensures that the agreed-upon decisions and resolutions are recorded for future reference and serves as a basis for further project activities. 
 
By conducting a thorough requirements review, project teams can identify conflicts, prioritize requirements, facilitate collaboration, seek consensus, validate feasibility, and document changes. This process helps in resolving competing demands and balancing stakeholders' needs, ensuring that the final set of requirements represents a well-balanced and agreed-upon foundation for the project's success. 
 
Once we have verified the requirements for correctness, the next process to be carried out will be to define the project scope.
`,
image : "/docs/images/monitoringandcontrolling3.png"
          },

          {
            id : "Define_Project_Scope ",
            title : "Define Project Scope ",
            content : `Once the requirements are verified, the project team can undertake several activities to define the project scope effectively. We will look at those activities but first let’s understand what Scope is.  
 
Project Scope: 
The project scope defines the boundaries and extent of work that needs to be accomplished to deliver the project's objectives, deliverables, and outcomes. It outlines the specific activities, tasks, and work packages that are required to complete the project successfully. Project scope focuses on the work related to project planning, execution, and control. 
 
Example: Let's consider a project to develop a mobile application for a ride-sharing service. The project scope may include activities such as conducting market research, gathering requirements, designing the user interface, developing the mobile app, testing functionality, and deploying the application. The project scope identifies the specific tasks and deliverables associated with building the mobile app and does not extend to ongoing operation and maintenance of the app. 
 
Product Scope: 
The product scope refers to the features, functionalities, and characteristics of the final deliverable or product resulting from the project. It defines what the product or system will do and how it will meet the needs of its intended users or customers. Product scope focuses on the end result, the features of the product, and its capabilities. 
 
Example: Continuing with the previous example, the product scope for the mobile application would include features like user registration, ride request functionality, real-time tracking, payment integration, and user rating system. The product scope describes the specific functionalities and attributes that the product, i.e., the mobile app, will possess. It defines what the app will offer to its users. 
Differences between Project Scope and Product Scope: 
 
Focus: The project scope focuses on the activities, tasks, and work required to complete the project, while the product scope focuses on the features, functionalities, and characteristics of the final deliverable. 
 
Timeframe: The project scope is typically time-bound, covering the duration of the project, including planning, execution, and control phases. The product scope pertains to the end result, which may have a longer lifespan beyond the project's completion. 
 
Perspective: The project scope is primarily concerned with the project team's activities and tasks, ensuring successful project execution. The product scope takes the perspective of the end-users or customers, emphasizing the features and functionalities that meet their needs and requirements. 
 
In summary, the project scope defines the work to be accomplished during the project's lifecycle, while the product scope defines the features and functionalities of the final product or deliverable. The project scope focuses on project management aspects, while the product scope focuses on the characteristics and capabilities of the end product. 
 
 
Now, let’s have a look the activities that can be carried out by a Project team to define the scope taking the verified requirements documents as reference.  
 
 
Identify Project Objectives: Clearly articulate the project objectives and align them with the organization's overall goals and strategic objectives. The project objectives should be specific, measurable, achievable, relevant, and time-bound (SMART) to provide a clear direction for the project. The Project Charter can be referred for identifying the objectives.  
 
Determine Deliverables: Identify the specific deliverables that need to be produced as part of the project. Deliverables are the tangible or intangible results or outcomes that the project will deliver. Clearly define and document each deliverable to ensure a shared understanding among team members and stakeholders. 
 
Define Project Boundaries: Establish the boundaries of the project by clearly defining what is included and what is excluded from the project scope. This helps prevent scope creep and ensures that everyone understands the project's limits and focus areas. 
 
Identify Constraints and Assumptions: Identify any constraints or limitations that may impact the project scope, such as budget, time, resources, or technological constraints. Additionally, document any assumptions that have been made during the requirements verification process and validate their accuracy. 
 
 	 
Engage Stakeholders: Collaborate with key stakeholders to gather their input and ensure their perspectives are considered in defining the project scope. Conduct meetings, workshops, or interviews to solicit feedback and address any concerns or requirements that may have been missed. 
 
Document Scope Statement: Create a scope statement that provides a clear and concise description of the project scope. The scope statement should include information such as project objectives, deliverables, boundaries, constraints, and assumptions. It serves as a reference document for all stakeholders and provides a shared understanding of the project's scope. 
 
Review and Finalize Scope Statement: Review the scope statement with stakeholders and obtain their approval and agreement. Incorporate any necessary revisions or clarifications based on the feedback received. Once finalized, ensure that all stakeholders are aware of and aligned with the defined project scope. 
 
Obtain Sign-Off: Seek formal sign-off or approval from relevant stakeholders, including the project sponsor or key decision-makers, to confirm their acceptance of the defined project scope. This sign-off indicates their commitment and agreement to the scope and provides a baseline for subsequent project activities. 
 
By conducting these activities, the project team can effectively define the project scope based on the verified requirements, ensuring clarity, alignment, and shared understanding among stakeholders. This defined project scope serves as a foundation for planning, executing, and controlling the project. 
`,
          },

          {
            id : "Project_Scope_Statement ",
            title : "Project Scope Statement ",
            content : `A project scope statement is a document that provides a clear and comprehensive description of the project's objectives, deliverables, boundaries, constraints, and assumptions. It serves as a formal agreement between the project team and stakeholders, establishing a shared understanding of what will be included and excluded from the project. The project scope statement is typically created during the Define Scope process and is a fundamental component of the project management plan. 
 
Key components of a project scope statement may include: 
 
Project Objectives: Clearly state the high-level goals and objectives the project aims to achieve. Objectives should be specific, measurable, achievable, relevant, and time-bound (SMART). 
 
Deliverables: Identify the tangible or intangible outcomes that the project will produce or deliver. Clearly describe the specific products, services, or results that will be provided to meet the project's objectives. 
 
Project Boundaries: Define the limits and boundaries of the project. This section outlines what is included in the project scope and what is explicitly excluded. It helps manage expectations and prevent scope creep. 
 
Assumptions: Document any assumptions that have been made during the project scoping process. Assumptions are factors or conditions that are believed to be true but are not proven. It is important to identify and communicate assumptions to ensure a shared understanding among stakeholders. 
 
Constraints: Identify any limitations or restrictions that may impact the project. Constraints can include budgetary constraints, time constraints, resource limitations, technological constraints, or regulatory requirements. Clearly defining constraints helps manage project expectations and guides decision-making. 
 
Key Stakeholder Identification: Identify the key stakeholders who have an interest in or influence over the project. This section provides an overview of the primary individuals, groups, or organizations that will be involved or affected by the project. 
 
Approval and Sign-Off: Include a section for formal approval and sign-off of the project scope statement. This ensures that all relevant stakeholders have reviewed and agreed to the defined scope and provides a baseline for managing scope changes. 
 
The project scope statement serves as a reference and guiding document throughout the project lifecycle. It helps project managers and team members make informed decisions, manage project boundaries, and communicate the project's scope to stakeholders. 
 
Once the Project Scope Statement is completed, sign-off completed with relevant stakeholders, the team can use the document to create the project plan. In order to do so, the team should breakdown the deliverables mentioned in the Scope Statement to smaller, easily manageable piece of work. This is achieved by Creating a Work Breakdown Structure, which is our next process discussion.
`,
image : "/docs/images/monitoringandcontrolling4.png"
          },
          {
            id : "Create_WBS ",
            title : "Create WBS (Work Breakdown Structure) ",
            content : `A Work Breakdown Structure (WBS) is a hierarchical decomposition of the project's deliverables, work packages, and activities into smaller, more manageable components. It organizes the project's work into distinct levels of detail, breaking it down from high-level deliverables into smaller, more manageable tasks. The WBS provides a structured framework for planning, organizing, and controlling project activities.`,
            image : "/docs/images/wbs.png"
          },
          {
            content : `Key points about the Work Breakdown Structure (WBS) include: 
 
Hierarchical Structure: The WBS follows a hierarchical structure, typically displayed as a tree-like diagram. The top-level represents the major deliverables or project phases, and each subsequent level breaks down the work into smaller components. 
 
Deliverable-Oriented: The WBS focuses on deliverables, which are the tangible or intangible outputs of the project. Each level of the WBS represents a deliverable, with subsequent levels decomposing it further into smaller deliverables or work packages. 
 
Decomposition: Decomposition is the process of breaking down deliverables into smaller, more manageable components. Each component in the WBS represents a discrete unit of work that can be estimated, scheduled, assigned, and tracked. 
 
Work Packages: Work packages are the lowest level of the WBS and represent the smallest units of work. They are typically defined as individual tasks or activities that can be completed within a relatively short timeframe. 
 
Scope Control: The WBS serves as a basis for scope control, ensuring that all project work is identified and accounted for. It helps prevent scope creep by providing a clear framework to evaluate and manage changes. 
 
Foundation for Planning: The WBS provides the foundation for project planning, as it helps identify the sequence of work, resource requirements, and dependencies between tasks. It facilitates the estimation of effort, duration, and costs associated with each work package. 
 
Communication Tool: The WBS serves as a communication tool to effectively communicate project scope and structure to stakeholders. It provides a visual representation of the project's components, facilitating understanding and collaboration among team members and stakeholders. 
 
Integration with Other Project Management Processes: The WBS is integrated with various project management processes, including scheduling, resource allocation, cost estimation, risk management, and quality management. It provides a framework for these processes to be developed and executed. 
 
By creating a WBS, project managers can break down complex projects into manageable pieces, facilitate planning and control, allocate resources effectively, and ensure that all project work is accounted for. It promotes a systematic and structured approach to project management, enabling efficient execution and successful project delivery. 
 
`
          },
          {
            id : "WBS_Dictionary",
            title : "WBS Dictionary",
            content : `A Work Breakdown Structure (WBS) dictionary is a supporting document that provides detailed information about each component or work package within the WBS. It complements the hierarchical structure of the WBS by offering additional descriptions, specifications, and guidelines for each element.`,
            image : "/docs/images/wbsdirectory.jpg"
          },
          {
            content : ` 
The WBS dictionary typically includes the following information: 
 
WBS Element Identifier: A unique identifier or code for each component or work package in the WBS. This identifier helps in tracking and referencing specific elements. 
 
Description: A detailed description of the component or work package, explaining its purpose, scope, and deliverables. This description helps stakeholders and team members understand the specific nature of the work. 
 
Scope: The specific boundaries and limits of the work package, detailing what is included and what is excluded. This clarifies the extent of each work package and prevents scope ambiguity. 
 
Deliverables: A list of the tangible or intangible outputs that will be produced as part of the work package. This provides a clear understanding of the expected results or outcomes. 
 
Dependencies: Identification of any dependencies or relationships between the work package and other components or activities. This helps in scheduling and coordinating the work effectively. 
 
Resources: The resources required to complete the work package, including personnel, equipment, materials, and tools. This information assists in resource allocation and planning. 
 
Duration: The estimated time or duration required to complete the work package. This supports project scheduling and enables the allocation of appropriate time resources. 
 
Cost Estimates: The estimated cost associated with the work package, including labor, materials, and any other expenses. This aids in budgeting and cost control. 
 
Responsible Party: The individual or team responsible for executing and delivering the work package. This clarifies accountability and helps in assigning and tracking responsibilities. 
 
Acceptance Criteria: The criteria or standards that must be met for the work package to be considered complete and accepted. This provides clear guidelines for quality control and acceptance. 
 
Risks and Assumptions: Identification of potential risks and assumptions associated with the work package. This helps in risk management and ensures that assumptions are documented and validated. 
 
The WBS dictionary serves as a reference guide for project team members, stakeholders, and other interested parties. It provides detailed information about each work package, supporting effective planning, execution, and control of project activities. The dictionary enhances communication, promotes clarity, and facilitates a common understanding of the project's components and requirements. 
 
With Creation of WBS and WBS dictionary, the scope for the project is fixed and once signed off, the Scope Baseline for the project is ready based on which the project performance related to scope of work is measured.  
`
          },
          {
            id  :"Scope_Baseline ",
            title : "Scope Baseline ",
            content :  `A scope baseline is a key component of the project management plan that represents the approved and agreed-upon project scope. It serves as a reference point for evaluating and managing changes to the project's scope throughout its lifecycle. The scope baseline consists of three primary elements: 
 
Project Scope Statement: The project scope statement describes the project's objectives, deliverables, boundaries, constraints, and assumptions. It provides a comprehensive understanding of what is included and excluded from the project scope. 
 
WBS (Work Breakdown Structure): The Work Breakdown Structure decomposes the project's deliverables and work packages into smaller, more manageable components. It presents the hierarchical structure of the project's scope and defines the relationship between various elements. 
 
WBS Dictionary: The WBS dictionary provides detailed information about each component or work package within the WBS. It includes descriptions, specifications, dependencies, resources, duration, and other relevant details. 
 
The scope baseline represents the approved version of these documents and is used as a benchmark for scope control. Any changes to the project's scope must be carefully evaluated against the scope baseline to determine their impact on the project. 
 
 
The scope baseline plays a crucial role in project management by providing a solid foundation for planning, executing, and controlling project activities. It helps in: 
 
Scope Management: The scope baseline serves as a basis for defining and managing the project's scope. It ensures that all project work is identified, controlled, and completed within the agreed-upon boundaries. 
 
Change Control: Any proposed changes to the project's scope are evaluated against the scope baseline. This comparison helps determine whether the changes are within the approved scope or require formal change requests and additional approvals. 
 
Performance Measurement: The scope baseline serves as a reference point for measuring the project's performance. It provides a clear understanding of the project's expected deliverables, enabling the evaluation of progress and performance against the defined scope. 
 
Scope Verification: During scope verification, the scope baseline is used to confirm that all project deliverables have been completed as planned. It ensures that the project's outputs align with the approved scope. 
 
The scope baseline, once established and approved, should be carefully managed, and documented throughout the project's lifecycle. It provides a stable framework for project control and helps maintain focus on the agreed-upon project objectives. 
 
 
Next step in the project is to build the project schedule, for which there are multiple processes to be followed as below: 
 `,
          },     
        ]
    },

    {
        "id": "developing_a_project_schedule",
        "title": "Developing a Project Schedule",
        "sections": [
          {
            "id": "Developing_a_Project_Schedule ",
            "title": "Developing a Project Schedule",
            "content": `Developing a project schedule involves several key processes, including defining activities, sequencing activities, estimating activity durations, and developing the final schedule.`,
            image : "/docs/images/monitoringandcontrol5.png"
          },
          {
            content : `Time is often one of the most critical constraints in project management. Ensuring that a project is completed on time requires careful planning and control, which is where Plan Schedule Management comes into play. This article will delve into what Plan Schedule Management is, its importance, the key components of a Schedule Management Plan, and the steps to effectively implement it in your projects. 
 
Plan Schedule Management is the process of establishing the policies, procedures, and documentation for planning, developing, managing, executing, and controlling the project schedule. This process results in the creation of the Schedule Management Plan, a crucial document that guides the project team on how to manage the project schedule throughout the project lifecycle. 
 
The importance of planning Schedule Management 
 
1	Time Management: Plan Schedule Management provides a clear framework for managing time effectively in a project. It ensures that all project activities are scheduled appropriately, resources are allocated efficiently, and potential delays are identified and addressed promptly. 
2	Predictability: A well-defined Schedule Management Plan enhances the predictability of the project timeline. It allows the project manager and stakeholders to anticipate milestones, deadlines, and potential bottlenecks, thereby reducing uncertainty and increasing confidence in the project’s timely delivery. 
3	Stakeholder Alignment: The Schedule Management Plan aligns the project team and stakeholders on the timing of key deliverables. This alignment is crucial for coordinating efforts, managing expectations, and ensuring that all parties are on the same page regarding the project’s timeline. 
4	Control Over the Project Timeline: By setting out clear procedures for schedule management, the plan allows the project manager to maintain control over the project timeline. This control helps prevent schedule slippage, ensuring that the project stays on track and meets its deadlines. 
`,
          },
          {
            id : "Key_Components_of_a_Schedule_Management_Plan ",
            title : "Key Components of a Schedule Management Plan ",
            content : `The Schedule Management Plan, which is the output of the Plan Schedule Management process, typically includes the following components: 
 
1	Scheduling Methodology: This section outlines the approach that will be used to develop the project schedule. Common methodologies include critical path method (CPM), critical chain, or agile approaches. It also includes the scheduling tools and software that will be used. 

2	Level of Accuracy: The Schedule Management Plan defines the acceptable level of accuracy for the schedule. This might involve specifying acceptable ranges for activity duration estimates or outlining how contingencies will be managed. 

3	Units of Measure: Clearly defining the units of measure (such as hours, days, or weeks) is essential for consistency in scheduling. The plan should specify the units that will be used for different types of work, such as development, testing, or review phases. 

4	Control Thresholds: This section outlines the control thresholds or variances allowed before corrective action is required. For example, the plan might specify that if a task is delayed by more than a certain percentage, it triggers a review or requires escalation. 

5	Schedule Baseline: The schedule baseline is the approved version of the project schedule, which can only be changed through formal change control procedures. The Schedule Management Plan should detail how the baseline will be established and maintained. 

6	Performance Measurement: The plan should describe how schedule performance will be measured. This could include specific metrics such as Schedule Performance Index (SPI) or variance analysis, and it should also outline the tools and techniques to be used for tracking progress. 

7	Reporting Formats: This component defines how schedule information will be reported to stakeholders. It specifies the format, frequency, and distribution method for schedule reports, ensuring that all stakeholders receive timely and relevant updates. 

8	Schedule Model Maintenance: The Schedule Management Plan should describe how the schedule model will be maintained throughout the project. This includes updating the schedule to reflect actual progress, managing changes, and ensuring that the schedule remains realistic and achievable. 
`
          },
          {
            id : "Guidelines_for_Plan_Schedule_Management ",
            title : "Guidelines for Plan Schedule Management ",
            content : `1	Engage Stakeholders in Scheduling: Involving key stakeholders in the scheduling process helps ensure that the schedule is realistic and aligned with stakeholder expectations. Their input can provide valuable insights and help in identifying potential risks or constraints.

2	Use Historical Data: Leverage historical data from similar projects to inform your scheduling estimates. This data can provide a benchmark for activity durations and help in developing a more accurate project schedule. 

3	Incorporate Buffer Time: Always include buffer time (also known as contingency) in your schedule to account for unexpected delays or changes. This helps in maintaining flexibility and ensures that minor issues do not cause significant schedule slippage. 

4	Regularly Update the Schedule: A project schedule is a living document that should be updated regularly to reflect actual progress. Regular updates help in identifying trends, predicting future performance, and making informed decisions. 

5	Communicate Schedule Changes Promptly: Any changes to the schedule should be communicated to stakeholders as soon as possible. This ensures that everyone is aware of the updated timeline and can adjust their plans accordingly. 

6	Use Project Management Software: Utilize project management software to automate scheduling tasks, track progress, and generate reports. Tools such as Microsoft Project, Primavera, or Smartsheet can help streamline the scheduling process and improve accuracy. 
`,
          },
          {
            id : "Define_Activities ",
            title : "Define Activities:",
            content : `The first step in developing a project schedule is to identify and define all the activities required to complete the project. Activities are the smallest units of work that contribute to achieving the project's objectives. This process involves breaking down the project's deliverables into manageable tasks and creating a comprehensive list of activities. 
 
Sequence Activities: 
Once the activities are defined, the next step is to determine their logical sequence or order. 
Sequencing activities involves identifying the dependencies between activities. 
Dependencies can be of two types: predecessor relationships (where one activity must be completed before another can start) and successor relationships (where an activity cannot start until its predecessor is completed). This process helps create a roadmap that shows the order in which activities should be executed. 
 
Estimate Activity Duration: 
Estimating activity durations involves assessing the time required to complete each activity. This process considers various factors, such as the complexity of the task, available resources, dependencies, and any constraints or risks associated with the activity. 
Estimation can be done using historical data, expert judgment, analogous activities, or other estimation techniques. The goal is to determine realistic and achievable durations for each activity. 
 
Develop Schedule: 
Once the activities are defined, sequenced, and their durations estimated, the project schedule can be developed. This process involves assigning start and end dates to each activity based on their dependencies, durations, and any constraints or deadlines. Project management software or tools can be used to create a visual representation of the schedule, such as a Gantt chart. The schedule should also consider resource availability, potential conflicts, and critical path analysis to ensure an optimized and realistic timeline for the project. 
 
Throughout these processes, it is crucial to engage relevant stakeholders, gather their inputs, and consider their expertise and perspectives. Regular updates and adjustments may be necessary as the project progresses, new information emerges, or unforeseen circumstances arise. Effective communication and collaboration among the project team members play a vital role in developing an accurate and reliable project schedule. 
 `,
 image : "/docs/images/monitoringandcontrol6.png"
          },
          {
            content : `Defining activities in a project is a collaborative process that involves the project team, with the project manager playing a central role. Here's how the project team would typically define activities, and the project manager's role in the process: 
 
Work Breakdown Structure (WBS): 
The project manager initiates the process by reviewing the Work Breakdown Structure (WBS) in collaboration with the team and identifies the work packages. 
 
Brainstorming and Collaboration: 
The project manager, then facilitates brainstorming sessions or workshops involving the project team to identify all the necessary activities required to complete each work package. During these sessions, the team members contribute their expertise and knowledge to ensure that all relevant activities are identified. 
 
Activity Definition: 
Based on the input from the team, the project manager leads the process of defining the activities. One way of ensuring correct definition of activities is to use a template of SMART: specific, measurable, achievable, relevant, and time-bound. The project manager ensures that all activities are clearly described, with well-defined objectives, inputs, outputs, and any necessary resources or constraints. 
 
Activity Dependencies: 
The project manager, in collaboration with the team, determines the logical sequence of the activities. They identify the dependencies between activities, such as which activities must be completed before others can start. The project manager also considers any external dependencies, such as dependencies on suppliers or stakeholders. 
 
`,
          },
          {
            id : "Documentation",
    title : "Documentation: ",
    content : `The project manager is responsible for documenting the defined activities, along with their descriptions, dependencies, and any additional information necessary for understanding and executing the activities. This documentation serves as a reference for the project team and helps maintain clarity and consistency throughout the project. 
 
Validation and Review: 
Once the activities are defined, the project manager conducts a review or validation process with the project team. This step ensures that all team members agree on the defined activities and their sequencing. It allows for any necessary adjustments or refinements based on the team's collective knowledge and expertise. 
 
Overall, the project manager plays a pivotal role in guiding the project team through the process of defining activities. They facilitate collaboration, ensure the completeness and accuracy of activity definitions, and align the activities with the project objectives and constraints. Effective communication and coordination between the project manager and the team are crucial for successful activity definition and subsequent project execution. 
`,
          },
          {
            id : "Activity_List_and_Activity_attributes ",
            title : "Activity List and Activity attributes ",
            content : `In project management, an activity list and activity attributes are two essential components used to define and describe the project activities in detail. Let's explore each of these concepts: 
 
Activity List: 
The activity list is a comprehensive and structured document that provides a complete inventory of all the activities required to accomplish the project's objectives. It serves as a breakdown of the work packages defined in the Work Breakdown Structure (WBS) and provides a detailed description of each activity. The activity list typically includes a unique identifier or code for each activity, a clear and concise activity description, and references to any relevant supporting documents or specifications. 
 
Activity Attributes: 
Activity attributes provide additional information and details about each activity listed in the project. They enrich the activity list by offering specific characteristics, dependencies, and requirements associated with each activity. Activity attributes are typically used to capture the following information: 
 
Activity Description: A detailed narrative or explanation of the activity, providing a clear understanding of its purpose, scope, and expected outcomes. 
Predecessors and Successors: The activities that must be completed before and after a particular activity, respectively. This information helps determine the logical sequencing of activities. 
Duration: The estimated or actual time required to complete the activity. It helps in scheduling and resource planning. 
Resource Requirements: The specific resources, such as personnel, equipment, or materials, needed to carry out the activity successfully. 
Constraints: Any limitations or restrictions that may impact the execution of the activity, such as budgetary constraints, regulatory requirements, or technological limitations. Dependencies: The external factors or conditions that an activity relies upon, such as inputs from other teams, approvals from stakeholders, or availability of certain deliverables. Assumptions: Any assumptions made during the activity planning process that could impact the activity's execution or outcomes. 
Risks: Any potential risks or uncertainties associated with the activity, including their likelihood and potential impacts. 
 
Activity attributes provide crucial details that inform project scheduling, resource allocation, risk management, and communication with stakeholders. They ensure that the project team has a comprehensive understanding of each activity and the context in which it will be executed, facilitating effective project planning and execution. 
 
Both the activity list and activity attributes are living documents that may evolve and be updated throughout the project's lifecycle as new information becomes available or changes occur. 
`,
image : "/docs/images/monitoringandcontrol7.png"
          },

          {
            id : "Sequencing_activities  ",
            title : "Sequencing activities  ",
            content : `After defining the activities and creating the activity list, the project manager and project team need to perform several tasks to sequence the activities effectively. Here's an overview of the steps typically involved: 
 
1 Identify Dependencies: The project manager and team members review the activity list and identify the dependencies between activities. Dependencies can be categorized as finish-to-start (FS), start-to-start (SS), finish-to-finish (FF), or start-to-finish (SF). They determine which activities must be completed before others can start and which activities can be executed concurrently.`,
image : "/docs/images/identifydependency.jpg"
          },
          {
            content : `The different types of dependencies commonly used in project management are: 
 
Finish-to-Start (FS): 
Finish-to-Start is the most common type of dependency, where the successor activity cannot begin until the predecessor activity is completed. It represents a sequential relationship. For example: Activity B (successor) cannot start until Activity A (predecessor) is finished. Activity A could be "Designing the Website" and Activity B could be "Developing the Website." 
 
Start-to-Start (SS): 
Start-to-Start signifies that the successor activity can only begin when the predecessor activity has started. It allows for activities to run in parallel from their start points. For example: Activity B (successor) can start when Activity A (predecessor) has started. Activity A could be "Gathering Requirements" and Activity B could be "Conducting User Interviews." 
 
Finish-to-Finish (FF): 
Finish-to-Finish means that the successor activity cannot finish until the predecessor activity has finished. It signifies that two activities must complete simultaneously. For example: Activity B (successor) cannot finish until Activity A (predecessor) is finished. Activity A could be "Testing the Software" and Activity B could be "Verifying Test Results."  
 
Start-to-Finish (SF): 
Start-to-Finish denotes that the successor activity cannot finish until the predecessor activity has started. It is relatively less common and may require careful attention in defining the relationship. For example: Activity B (successor) cannot finish until Activity A (predecessor) has started. Activity A could be "Initiating Risk Assessment" and Activity B could be "Monitoring Risk Mitigation." 
 
It's important to note that these dependencies can be modified by adding lag or lead time to create delays or overlaps between activities. Dependencies are essential for determining the logical sequence of activities and creating an accurate project schedule.  
 
2	Determine Sequence Relationships: Based on the identified dependencies, the project manager and team establish the logical sequence relationships among the activities. This involves determining the order in which activities should be performed to achieve the desired project outcomes. 
 
3	Apply Precedence Diagramming Method (PDM): The project manager and team may utilize techniques like the Precedence Diagramming Method (PDM) to create a visual representation of the activity sequence- Schedule Network Diagram.   
 	 
The Precedence Diagramming Method (PDM) is a technique used in project management to visually represent the dependencies and logical relationships between activities in a project. It is a popular method for creating network diagrams, also known as activity-on-node (AON) diagrams. 
 
In PDM, activities are represented as nodes, and the dependencies between activities are represented by arrows. The nodes depict the start and finish of each activity, while the arrows indicate the flow and sequence of activities. 
 
Here are the key elements and symbols used in a Precedence Diagramming Method: 
 
Nodes: Nodes represent activities and are typically represented as rectangles or circles. Each node is labeled with an activity identifier or description. 
 
Arrows: Arrows connect the nodes and represent the dependencies between activities. The arrows indicate the direction of the dependency, starting from the predecessor activity and ending at the successor activity. 
 
Types of Dependencies: PDM allows for different types of dependencies to be depicted. The most common type is the Finish-to-Start (FS) dependency, where the successor activity cannot start until the predecessor activity is finished. Other types of dependencies include Start-to-Start (SS), Finish-to-Finish (FF), and Start-to-Finish (SF). 
 
Lag and Lead: PDM allows for the inclusion of lag or lead time between activities. Lag time represents a delay or waiting period between activities, while lead time represents an overlap or acceleration in the start or finish of activities. 
 
By using the Precedence Diagramming Method, project managers and teams can visually analyze and understand the sequence of activities, identify critical paths, determine project duration, and assess the impact of changes or delays on the overall project schedule. The network diagram created through PDM serves as a valuable tool for project planning, scheduling, and communication among project stakeholders.  
 
 	 
4	Develop a Network Diagram: Using the identified dependencies and sequence relationships, the project manager and team create a network diagram, such as a project schedule network diagram or a critical path diagram. This diagram visually represents the sequence and dependencies of activities, allowing for a clear understanding of the project's flow.`,
image : "/docs/images/precedence.jpg"
          },

          {
            id : "Sequencing_activities",
            content : `5	Document the Sequence: Once the activities are sequenced, the project manager updates the project documentation, including the activity list and any network diagrams. The sequence information is incorporated into the project schedule, ensuring that all stakeholders have a clear understanding of the planned order of activities. 
 
By following these steps, the project manager and team establish a logical and efficient sequence of activities, which forms the foundation for developing a robust project schedule. Regular review and updates to the activity sequence may be necessary as the project progresses and new information becomes available.`,
image : "/docs/images/monitoringandcontrol8.png"
          },

          {
            id : "Estimate_Activity_Duration",
            title : "Estimate Activity Duration",
            content : `When estimating activity durations, both the project team and the project manager collaborate to determine the time required to complete each activity. Several estimation techniques are commonly used in project management. Let's explore some of these techniques along with examples: 
 
Analogous Estimating: 
Analogous estimating involves drawing on historical data or past project experience to estimate durations for similar activities in the current project. The project manager and team compare the current activity with a similar activity completed in the past and adjust the duration based on any differences. For example, if a previous website development project took two months, the team can estimate a similar activity in the current project to also take around two months. 
 
Parametric Estimating: 
Parametric estimating involves using statistical models or mathematical algorithms to estimate activity durations based on specific parameters or variables. This technique uses historical data and statistical analysis to develop a formula or equation for calculating activity durations. For instance, based on historical data, the team might estimate that coding 1,000 lines of software takes an average of 20 hours, allowing them to estimate the duration for coding a larger software module. 
 
Three-Point Estimating: 
Three-point estimating involves considering three different estimates for each activity: the optimistic estimate (O), the most likely estimate (M), and the pessimistic estimate (P). These estimates are then used to calculate an average or weighted estimate. There are two commonly used three-point estimating techniques: Triangular distribution and Beta distribution 
 
a	Triangular Distribution: 
In the triangular distribution, the average estimate is calculated by taking the sum of the optimistic estimate, the most likely estimate, and the pessimistic estimate, and dividing it by three. For example, if the optimistic estimate is 4 weeks, the most likely estimate is 6 weeks, and the pessimistic estimate is 8 weeks, the average estimate would be (4+6+8)/3 = 6 weeks. 
 
b	Beta Distribution: 
The beta distribution uses a weighted average that considers the most likely estimate more heavily than the optimistic and pessimistic estimates. It involves assigning weights to the three estimates and calculating the average accordingly. For example, if the most likely estimate is assigned a weight of 4, the optimistic estimate a weight of 1, and the pessimistic estimate a weight of 6, the weighted average can be calculated using the formula: (O+4M+P)/6. 
 
Bottom-up Estimating: 
Bottom-up estimating involves estimating the duration for each individual task within an activity and then aggregating those estimates to arrive at the total activity duration. The project team breaks down complex activities into smaller, more manageable tasks, estimates the duration for each task, and then sums up the individual task durations to determine the overall activity duration. For example, in the activity "Writing a Software Manual," the team estimates the time required for each subsection (e.g., Introduction, Installation, Troubleshooting) and sums them up to estimate the activity duration. 
 
These estimation techniques help the project team and project manager arrive at realistic and informed estimates for activity durations. It's important to note that estimation is an iterative process, and as the project progresses and more information becomes available, the estimates may need to be refined and updated. 
 
 	 
Although selecting the correct estimation techniques would depend on the discussion between project manager and project team within the boundaries of organization’s expectations, points below can be used as suggestions for selecting an estimation technique in different scenarios:  

`,
image  :"/docs/images/table5rr.png",

          },
          {
            content : `It's important to note as reminder that these estimation techniques are not mutually exclusive, and different techniques can be used in combination or sequentially depending on the project's characteristics and the availability of data. Project managers often leverage their experience and judgment to select the most appropriate technique(s) based on the specific project circumstances. Once the activity duration is estimated, the team will start working on developing and documenting the project schedule.`,
            image : "/docs/images/monitoringandcontrol9.png"
          },

          {
            id : "Develop_Schedule",
            title : "Develop Schedule",
            content : `Once activity durations have been estimated, the project team and project manager collaborate to develop the project schedule. Here are the key steps involved in this process: 
 
 
Determine Start and Finish Dates: 
With the activity durations estimated, the project team and project manager determine the start and finish dates for each activity. They consider the project's start date, any constraints, and the interdependencies between activities to establish a realistic timeline. This process may involve backward or forward scheduling techniques. 
 
Review the Network Diagram: 
The visual representation of activities dependencies in a network diagram helps in providing a clear overview of the project's flow and critical path. 
 
Identify the Critical Path: 
The project team and project manager analyze the network diagram to identify the critical path. The critical path is the longest path through the network diagram and represents the sequence of activities that, if delayed, would directly impact the project's overall duration. Identifying the critical path helps in focusing resources and managing activities that are crucial to meeting project deadlines. 
 
 
Consider Constraints and Milestones: 
The project team and project manager consider any constraints or milestones that need to be met during the project. Constraints may include fixed deadlines, budget limitations, or regulatory requirements. They adjust the schedule accordingly to accommodate these constraints and ensure milestones are achieved. 
 
Review and Refine: 
The project team and project manager review the developed project schedule to ensure its accuracy, feasibility, and alignment with project objectives. They consider any potential risks or issues that may impact the schedule and make refinements as needed. 
 
Communicate and Obtain Approval: 
The project manager communicates the developed project schedule to stakeholders, including the project team, management, and other relevant parties. They seek feedback, address any concerns, and obtain approvals or sign-offs on the schedule. This ensures that everyone involved in the project is aware of the timeline and commitments. 
 
Throughout this process, the project manager plays a critical role in facilitating collaboration, making informed decisions, and balancing competing priorities to develop a realistic and achievable project schedule. The project schedule serves as a roadmap for the project, guiding the execution and monitoring of activities, and providing a basis for tracking progress and managing changes. 
 
Once the schedule document is ready, key stakeholders review the schedule and provide their acceptance by signing off the document. The signed-off project schedule becomes the Schedule baseline for measuring performance throughout the project. 
 
 
 
 	 
**Schedule Baseline** 
 
The schedule baseline is an approved version of the project schedule that serves as a reference point for measuring and tracking project progress. It represents the agreed-upon timeline and sequence of activities that the project team will adhere to throughout the project execution. 
 
The schedule baseline is established by finalizing the project schedule, including activity durations, dependencies, resource assignments, and milestones. It captures the planned start and finish dates for each activity, as well as the overall project duration. It also incorporates any constraints, such as fixed deadlines or resource limitations. 
 
Once the schedule baseline is established, it acts as a benchmark against which actual project progress is measured. Any deviations from the baseline are monitored and tracked to assess project performance and identify potential delays or variances. The baseline helps in evaluating whether the project is on track, behind schedule, or ahead of schedule. 
 
It serves as a point of comparison throughout the project lifecycle, enabling project managers to assess project health, make informed decisions, and take corrective actions when necessary. 
 
It's important to note that changes to the project schedule can occur due to unforeseen circumstances or evolving project requirements. However, any changes made to the schedule after the baseline is established are considered as schedule updates or revisions, and they should be properly documented and communicated to stakeholders. The original baseline remains as a reference point for assessing the impact of schedule changes and tracking the overall progress of the project. 
 
 	 
**Schedule network analysis**  
 
Schedule network analysis is a project management technique used to analyze and determine the critical path, project duration, and overall project schedule. It involves the evaluation and sequencing of project activities, identification of dependencies, and calculation of activity durations and total project duration. Schedule network analysis is typically performed during the planning phase of a project to establish a realistic and achievable project schedule. Here is a detailed explanation of the key components and steps involved in schedule network analysis: 
 
Activity Definition: The first step in schedule network analysis is to define the individual project activities. Activities are specific tasks or work packages that need to be performed to accomplish the project objectives. Each activity is described in terms of its scope, deliverables, and dependencies on other activities. 
 
Activity Sequencing: Once the activities are defined, the next step is to determine the sequence in which they need to be performed. Activity sequencing involves identifying the logical relationships and dependencies between activities. These dependencies can be of four types: finish-to-start (FS), start-to-start (SS), finish-to-finish (FF), and start-to-finish (SF). 
 
Network Diagramming: Network diagramming is the graphical representation of the project activities and their dependencies. The most commonly used diagramming technique is the Precedence Diagramming Method (PDM), which uses nodes to represent activities and arrows to represent dependencies. The network diagram provides a visual representation of the project's flow and helps in identifying the critical path. 
 
Critical Path Analysis: The critical path is the longest path of activities through the network diagram and represents the minimum time required to complete the project. Critical path analysis involves identifying the critical path by calculating the total duration of each path in the network. Activities on the critical path have no float or slack and must be completed on time to avoid project delays. 
 
Activity Duration Estimation: Estimating the duration of each activity is an essential step in schedule network analysis. It involves assessing the time required to complete each activity based on factors such as resource availability, skill levels, productivity rates, and any constraints or dependencies. Activity durations can be estimated using techniques such as expert judgment, historical data analysis, analogous estimation, or three-point estimation. 
 
Schedule Calculation: Once the activity durations are estimated, the project schedule is calculated by determining the start and end dates of each activity based on the activity sequencing and dependencies. The schedule calculation takes into account any constraints, resource availability, and project priorities. It involves forward and backward pass calculations to determine the early start, early finish, late start, and late finish dates for each activity. 
 
Schedule Compression: Schedule compression techniques are applied when there is a need to shorten the project duration or meet tight deadlines. Techniques such as crashing and fast-tracking can be used to compress the schedule by reducing activity durations or overlapping activities. However, schedule compression should be carefully managed to avoid compromising quality or increasing project risks. 
 
Schedule Baseline: The final step in schedule network analysis is to establish a schedule baseline. The baseline represents the approved project schedule and serves as a reference point for monitoring and controlling the project's progress. It includes the planned start and finish dates for each activity, as well as the overall project duration. 
 
 
Benefits of Schedule Network Analysis: 
 
Identifying the critical path and project duration: Schedule network analysis helps in determining the critical path, which is essential for project planning and resource allocation. It provides insights into the longest path of activities and the minimum time required to complete the project. 
 
Visualizing activity dependencies: Network diagrams provide a visual representation of activity dependencies, allowing project teams to understand the relationships between activities and identify potential bottlenecks or constraints. 
 
Optimizing project schedule: By analyzing the sequence and duration of activities, schedule network analysis enables project managers to optimize the project schedule, balance resource utilization, and identify opportunities 
 
 
 	 
**Resource optimization**  
 
Resource optimization techniques, such as resource leveling and resource smoothing, are employed in project management to effectively manage and allocate resources while balancing project requirements and constraints. These techniques ensure that resources are utilized efficiently and that project schedules are achievable within resource limitations. Let's explore each technique with examples: 
 
Resource Leveling: 
Resource leveling aims to resolve resource conflicts by redistributing resources over time to eliminate or minimize resource overloads. It involves adjusting the start and finish dates of activities to smooth out resource utilization while keeping the project schedule as intact as possible. Resource leveling helps to avoid resource bottlenecks and ensure a more balanced allocation of resources throughout the project. 
Example: 
Imagine a construction project with two critical activities: "Concrete Pouring" and "Plumbing Installation." Both activities require the same group of workers. However, during the project planning phase, it is identified that the workers' availability is limited during a specific time period, resulting in resource overloads. 
 
To address this issue, resource leveling may be applied. The start and finish dates of either the "Concrete Pouring" or "Plumbing Installation" activities can be adjusted, creating a more balanced resource allocation. For instance, if the workers' availability is limited in Week 5, the start date of the "Plumbing Installation" activity can be shifted to Week 6 to alleviate the resource constraint. 
 
Resource Smoothing: 
Resource smoothing is a technique used to adjust the resource utilization rate to minimize fluctuations and maintain a more constant level of resource demand throughout the project duration. The objective is to ensure that resource usage remains within predefined limits while optimizing the project schedule. Resource smoothing typically allows slight flexibility in the start and finish dates of activities within their permissible limits. 
Example: 
Consider a software development project with two critical activities: "Coding" and "Testing." Both activities require the same pool of developers. However, during the initial project planning, it is observed that resource utilization spikes during certain periods due to the heavy workload. 
 
To address this issue, resource smoothing can be employed. The start and finish dates of the activities can be adjusted within their permissible limits to achieve a more balanced resource allocation. For instance, if the resource utilization is high in Week 8, the "Coding" activity's finish date can be extended to Week 9, while keeping the overall project duration intact. 
 
Resource leveling and resource smoothing can be used individually or in combination, depending on the project's specific requirements and constraints.  
Schedule Compression 
Schedule compression techniques, such as crashing and fast tracking, are employed in project management to reduce the project duration and meet tight deadlines. These techniques involve making adjustments to the project schedule, activities, or resources to expedite project completion. Let's explore each technique with examples: 
 
Crashing: 
 
Crashing involves adding additional resources or increasing the intensity of resources assigned to critical path activities to reduce their duration. This technique aims to achieve the shortest possible project duration by allocating more resources to critical activities. The additional resources may include adding more team members, increasing work shifts, or utilizing overtime. 
 
Example: Let’s say you are managing a construction project with a critical activity of “Concrete Pouring” that has an estimated duration of 10 days. However, the project deadline needs to be shortened by 3 days. By applying the crashing technique, you can increase the number of workers assigned to the “Concrete Pouring” activity, allowing it to be completed in 7 days instead of the original 10. This can be achieved by working overtime or bringing in additional labor resources. By crashing the critical activity, the project duration is reduced by 3 days, meeting the tight deadline. 
 
Fast Tracking: 
 
Fast tracking involves overlapping or performing activities in parallel that were originally planned to be sequential. This technique aims to reduce project duration by executing critical activities concurrently, instead of waiting for one activity to finish before starting the next. It involves taking calculated risks and compressing the project schedule by overlapping activities with dependencies. 
Example: Consider a software development project with a critical activity sequence of "Coding" followed by "Testing." The original plan was to complete the "Coding" phase before starting the "Testing" phase. However, to expedite the project and meet a tight deadline, fast tracking can be applied. In this case, some testing activities can begin while coding activities are still in progress. For instance, the testing team can start preparing test cases or conducting unit testing on the completed code modules while the coding team continues with the remaining coding tasks. By overlapping these activities, the project duration is shortened as both activities are carried out simultaneously, expediting the project completion. 
 
Both crashing and fast tracking techniques come with certain considerations and risks. While they can reduce project duration, they may also result in increased costs, resource constraints, or potential compromises on quality. Project managers need to carefully analyze the project's constraints, critical path, and stakeholder expectations before implementing these techniques. 
 
 
Next, the project team should create the project budget based on the activities identified.`,

          }
        ]
    },


        {
            "id": "determining_the_project_budget",
            "title": "Determining the project budget  ",
            "sections": [
              {
                "id": "Determining_the_project_budget ",
                "title": "Determining the project budget ",
                "content": ` 
Developing a project budget involves estimating the costs associated with project activities and determining the overall budget required to execute the project successfully. Here are the key processes involved in developing a project budget: 
`,
                image : "/docs/images/monitoringandcontrol10.png"
              }, 

              {
                id : "Plan_cost_management ",
                title : "Plan cost management ",
                content : `Effective cost management is a cornerstone of successful project delivery. Ensuring that a project is completed within its approved budget requires a structured approach to planning, estimating, budgeting, and controlling costs. This is where the Plan Cost Management process comes into play. In this article, we will explore the importance of Plan Cost Management, its key components, and how to effectively implement it in your projects. 
 
 
Plan Cost Management is the process of establishing the policies, procedures, and documentation necessary for planning, managing, expending, and controlling project costs. This process results in the creation of the Cost Management Plan, a critical document that outlines how project costs will be estimated, budgeted, managed, monitored, and controlled throughout the project lifecycle. 
 
The Importance of Plan Cost Management 
1	Budget Control: Plan Cost Management provides a framework for keeping the project within its financial limits. By clearly defining how costs will be managed, the process helps prevent budget overruns and ensures that the project delivers value within the agreed-upon budget. 
2	Financial Predictability: A well-structured Cost Management Plan enhances the predictability of project finances. It allows project managers and stakeholders to forecast costs more accurately, anticipate financial risks, and make informed decisions to keep the project on track. 
3	Stakeholder Confidence: The Cost Management Plan aligns the project team and stakeholders on the financial expectations and constraints of the project. This alignment is crucial for securing stakeholder confidence and support, as it demonstrates that the project is financially viable and well-managed. 
4	Resource Allocation: By outlining how costs will be estimated and controlled, the Cost Management Plan ensures that resources are allocated efficiently. This helps avoid wastage, optimizes resource use, and ensures that the project remains financially sustainable. 
 
**Key Components of a Cost Management Plan** 
 
The Cost Management Plan, which is the output of the Plan Cost Management process, typically includes the following components: 
 
1	Cost Estimation Methodology: This section outlines the approach that will be used to estimate project costs. It defines the tools, techniques, and data sources that will be used to develop cost estimates, such as analogous estimating, parametric estimating, bottom-up estimating, or expert judgment. 
2	Budgeting Process: The budgeting process defines how the project budget will be developed, approved, and monitored. It includes the process for aggregating estimated costs to establish the cost baseline, which is the approved budget against which project performance will be measured. 
3	Cost Control Procedures: This component describes the procedures that will be used to control costs throughout the project. It includes the process for monitoring cost performance, managing cost variances, and implementing corrective actions to keep the project within budget. 
4	Reporting Formats: The Cost Management Plan should define the formats, frequency, and distribution methods for cost reports. These reports are used to communicate cost performance to stakeholders and to provide a basis for decisionmaking. 
5	Control Thresholds: Control thresholds define the allowable variances in cost performance before corrective action is required. For example, the plan might specify that if actual costs exceed the budget by a certain percentage, it triggers a review or requires escalation. 
6.Funding Requirements: This section outlines the funding requirements for the project, including the timing and amounts of funding needed. It ensures that the project has access to the necessary financial resources at the right times to support project activities. 
7.Earned Value Management (EVM): If applicable, the Cost Management Plan may include a description of how Earned Value Management will be used to integrate cost, schedule, and scope performance. EVM provides a powerful tool for assessing project performance and predicting future outcomes. 
 
**Guidelines for Planning Cost Management** 
 
1	Involve Key Stakeholders in Cost Planning: Engage key stakeholders early in the cost planning process to ensure that their expectations and constraints are considered. Their input can provide valuable insights and help in developing a more accurate and realistic cost management plan. 
2	Use Historical Data for Estimation: Leverage historical data from similar projects to inform your cost estimates. Historical data can provide benchmarks for cost estimation and help in identifying potential cost risks. 
3	Incorporate Contingency Reserves: Always include contingency reserves in your budget to account for unexpected events or risks. Contingency reserves provide a buffer that helps prevent budget overruns and ensures that the project remains financially viable. 
4	Regularly Review and Update the Budget: A project budget should not be static. Regularly review and update the budget to reflect actual costs, changes in scope, or new risks. This helps in maintaining an accurate and realistic view of the project’s financial status. 
5	Communicate Cost Changes Promptly: Any changes to the project budget should be communicated to stakeholders as soon as possible. This ensures that stakeholders are aware of the updated financial situation and can adjust their expectations or decisions accordingly. 
6	Use Project Management Software: Utilize project management software to automate cost tracking, reporting, and analysis. Tools such as Microsoft Project, Primavera, or specialized cost management software can help streamline the cost management process and improve accuracy. 
 
Plan Cost Management is a fundamental process in project management that lays the foundation for effective cost estimation, budgeting, and control. By developing a comprehensive Cost Management Plan, project managers can ensure that their projects stay within budget, deliver the expected value, and maintain financial viability. Following best practices in Plan Cost Management will help you manage costs effectively, align stakeholder expectations, and maintain control over the project’s financial performance, ultimately leading to a successful project.`,
image : "/docs/images/estimate.png"
              },

              {
                id : "Estimate_Costs ",
                title : "Estimate Costs ",
                content : `The first step in developing a project budget is to estimate the costs associated with each activity or work package. The project team, with the assistance of subject matter experts, evaluates the resources and inputs required for each activity and estimates the corresponding costs. This process involves identifying and considering various cost categories. 
 
**Understanding Cost Categories:** 
Before estimating costs, it's crucial to have a clear understanding of the cost categories typically encountered in projects. These categories may vary depending on the project, but common ones include: 
 
Direct Costs: These are costs directly attributable to a specific activity or work package. They include labor costs, materials, equipment, and any other resources needed to complete the activity. 
 
Indirect Costs: Also known as overhead costs, these are expenses incurred to support the project but are not directly tied to a specific activity. Examples include project management costs, utilities, rent, administrative expenses, and general supplies. 
 
Contingency Reserves: Contingency reserves are additional funds set aside to address unforeseen risks and uncertainties that may impact the project. They act as a buffer to mitigate potential cost overruns. 
 
Management Reserves: Management reserves are allocated for unplanned work or changes that may arise during the project. They are controlled by the project manager and serve as a contingency for unknown risks or changes. 
 
 
**Estimation Ranges** 
 
Estimation ranges are used in project management to report the level of accuracy and precision associated with cost and duration estimates at different stages of a project. The Project Management Body of Knowledge (PMBOK) provides guidance on three commonly used estimation ranges: Rough Order of Magnitude (ROM), Budget Estimate, and Definitive Estimate. Let's explore each of these ranges:`,
image : "/docs/images/linegraph.jpg",
              },
              {
                content : `Rough Order of Magnitude (ROM) Estimate: 
ROM estimates are rough, high-level approximations of project costs or durations. They are typically provided during the early stages of a project when limited information is available. ROM estimates have a wide range of accuracy, often spanning from -25% to +75% of the actual value. They are useful for initial project evaluations, feasibility studies, and early decision-making. ROM estimates help stakeholders get a sense of the project's scale and potential investment required. However, they carry significant uncertainty and should not be relied upon for precise planning or financial commitments. 
 
 
Budget Estimate: 
Budget estimates are developed with more detailed project information and serve as a baseline for project funding and financial planning. These estimates are provided at a stage when project requirements and scope are reasonably well-defined. Budget estimates have a moderate level of accuracy and typically range from -10% to +25%  or -15% to +30% of the actual value. They are used for cost control, budget allocation, and determining the financial resources required for the project. Budget estimates form the basis for securing project funding and setting financial targets. 
 
Definitive Estimate: 
Definitive estimates are the most accurate and precise estimates, developed when project requirements and scope are well-defined and detailed. These estimates provide a highly accurate projection of project costs or durations and are based on extensive analysis and data. Definitive estimates have a narrow range of accuracy, typically ranging from -5% to +10% of the actual value. They are used for detailed project planning, procurement, and contract negotiations. Definitive estimates help in making informed decisions, managing project budgets, and tracking project performance against planned targets. 
 
It's important to note that the estimation ranges mentioned above are general guidelines, and the actual ranges can vary based on project complexity, industry standards, and organizational practices. Project managers should assess the specific requirements and context of their projects to determine appropriate estimation ranges for reporting and decision-making purposes. 
 
By providing estimates within appropriate ranges at various stages of a project, stakeholders gain a realistic understanding of the uncertainties and risks associated with the project's cost and duration. This facilitates effective decision-making, budgeting, and resource allocation throughout the project lifecycle. 
`,
image : "/docs/images/determine.png"
              },
              {
                id : "Determine_Budget ",
                title : "Determine Budget ",
                content : `Once the costs for each activity are estimated, the next step is to determine the overall project budget. This involves aggregating the estimated costs for all activities, including direct and indirect costs, and accounting for any applicable contingency reserves and management reserves. 
In addition to the estimated costs, the project budget may also include provisions for procurement, quality assurance, risk management, and other project-related expenses. 
 
The project budget is typically presented in a comprehensive document that outlines the estimated costs for each activity, the total project cost, and the distribution of costs across different cost categories. 
 
Throughout the budget development process, it is essential to involve stakeholders, such as the project sponsor and relevant subject matter experts, to ensure accuracy and alignment with project goals and constraints. 
 
It's important to note that the project budget is a dynamic document and may undergo revisions as the project progresses and new information becomes available. Regular monitoring and control of project costs are crucial to ensure adherence to the budget and proper financial management throughout the project's lifecycle. 
 	 
**Budget components** 
 
When developing a project budget, several components are considered to ensure comprehensive financial planning and management. Here are the key budget components:`,
image : "/docs/images/budget.png"
              },
              {
                content : `Cost Estimate: 
The cost estimate is the calculated approximation of the costs associated with project activities. It involves determining the costs of labor, materials, equipment, services, and other resources required to complete the project. The cost estimate is typically based on the estimated quantities of resources and their corresponding unit costs. It serves as the foundation for determining the overall project budget. 
 
Cost Baseline: 
The cost baseline represents the approved version of the project budget that serves as a reference point for measuring and tracking cost performance throughout the project's lifecycle. It incorporates the estimated costs for each activity and the planned expenditures over time. The cost baseline is established by aggregating the individual activity cost estimates and any applicable overhead costs. Any changes to the budget after the baseline is set are considered as cost updates or revisions. 
 
 
Contingency Reserve: 
Contingency reserves are additional funds set aside to address unforeseen risks and uncertainties that may impact the project. They act as a buffer to mitigate potential cost overruns. The contingency reserve is determined based on factors such as project complexity, level of uncertainty, and the organization's risk tolerance. It is included in the project budget but is not allocated to specific activities unless the identified risks materialize. 
 
Project Budget: 
The project budget represents the total authorized funding for the project. It includes the estimated costs for all project activities, as well as the contingency reserve. The project budget provides a financial framework for executing and controlling the project. It guides project managers and stakeholders in making financial decisions, allocating resources, and monitoring expenditure against planned costs. 
 
Management Reserve: 
Management reserves are allocated for unplanned work or changes that may arise during the project. They are controlled by the project manager and serve as a contingency for unknown risks or changes. Management reserves are separate from the contingency reserve and are not included in the project budget. They are typically under the direct control of the project manager and are used to address unforeseen circumstances that could impact the project's scope, schedule, or budget. 
 
These budget components work together to ensure effective financial planning, control, and management throughout the project lifecycle. They provide a framework for estimating costs, establishing baselines, addressing risks, and allocating resources. Regular monitoring and control of the budget components help in ensuring that the project remains within budget and that any deviations are properly managed and communicated to stakeholders. 
 
 
 
 	 
Cost Baseline 
 
The cost baseline refers to the approved and finalized version of the project budget that serves as a reference point for measuring and tracking cost performance throughout the project's duration. It represents the authorized funding allocated to the project and provides a benchmark against which actual costs are compared. 
 
The cost baseline is established by aggregating the individual activity cost estimates, including the estimated costs for labor, materials, equipment, services, and other resources required to complete the project. It also includes any applicable overhead costs. The baseline is typically created during the planning phase of the project and is approved by relevant stakeholders, such as the project sponsor or steering committee. 
 
Once the cost baseline is set, it becomes an essential component of the project management plan. It provides a solid foundation for monitoring and controlling project costs, enabling project managers to track deviations from the original budget and take appropriate actions to address any variances. 
 
Throughout the project, actual costs are compared against the cost baseline to determine whether the project is progressing within the approved budget. Any deviations or changes to the budget are carefully analyzed and documented to ensure proper financial management. This comparison helps project managers and stakeholders evaluate the financial health of the project, make informed decisions, and take corrective measures if necessary. 
 
It's important to note that the cost baseline is a dynamic document that may undergo revisions as the project progresses. Changes to the baseline are typically managed through a formal change control process, ensuring that modifications to the budget are properly authorized and documented. 
 
In summary, the cost baseline represents the approved version of the project budget and serves as a reference for monitoring and controlling project costs. It provides a framework for assessing the financial performance of the project and guides decision-making throughout its lifecycle. 
 
 
 
 	 
**Performance Measurement Baselines** 
 
In project management, the performance measurement baseline (PMB) refers to the approved plan that serves as a basis for measuring and evaluating project performance. It encompasses three key elements: scope baseline, schedule baseline, and cost baseline. Together, these baselines provide a comprehensive framework for monitoring and controlling project performance. 
 
Scope Baseline: 
The scope baseline represents the approved version of the project scope statement, work breakdown structure (WBS), and project deliverables. It defines the boundaries and objectives of the project, including the specific work to be performed and the deliverables to be produced. The scope baseline helps project teams establish a common understanding of project requirements and serves as a reference for assessing scope changes. 
 
Schedule Baseline: 
The schedule baseline is the approved version of the project schedule. It includes the planned start and end dates for project activities, milestones, and major deliverables. The schedule baseline provides a roadmap for the project's timeline and sets expectations for the sequence and duration of project activities. It serves as a reference for tracking progress, identifying schedule variances, and making schedule-related decisions. 
 
Cost Baseline: 
The cost baseline represents the approved version of the project budget. It includes the estimated costs for all project activities, resources, and other cost elements. The cost baseline provides a financial framework for the project and serves as a benchmark against which actual costs are measured. It helps project teams monitor cost performance, identify deviations, and take corrective actions to manage the project's financial aspects. 
 
By establishing these baselines, project managers and stakeholders have a clear framework for measuring project performance against the planned objectives. Throughout the project lifecycle, actual performance is compared to the baselines to evaluate progress, identify deviations, and take appropriate actions. Variances from the baselines may indicate potential issues or changes that need to be addressed through project control mechanisms, such as change management processes or corrective actions. 
 
The performance measurement baseline is an essential tool for project monitoring, control, and decision-making. It allows project teams to assess the project's progress, track performance against planned objectives, and ensure alignment with stakeholder expectations. 
 	 
This covers the unit where we discussed setting performance target: Scope, Schedule, and cost Baseline in a Traditional approach project.  
`
              }
            ]
    }

]
    }
  ]
export default Chapter6;
