const KnowledgeAreaData = [
  // Integration Management Flashcards
  {
    category: "Integration Management",
    question: "Project Integration Management",
    answer: "The process of coordinating all project elements, ensuring that different processes and knowledge areas work together.",
    example: "In an LMS project, scope, schedule, and budget are aligned to ensure all parts work cohesively."
  },
  {
    category: "Integration Management",
    question: "Develop Project Charter",
    answer: "The process of formally authorizing a project and providing the project manager with authority.",
    example: "A project charter for a mobile learning app outlines objectives, stakeholders, and budget approval before work begins."
  },
  {
    category: "Integration Management",
    question: "Develop Project Management Plan",
    answer: "The process of compiling all subsidiary plans (scope, schedule, cost, risk, etc.) into a single document that guides execution.",
    example: "A project management plan for an EdTech platform includes sections for content development, platform design, and marketing strategy."
  },
  {
    category: "Integration Management",
    question: "Direct and Manage Project Work",
    answer: "The process of executing the project plan and producing deliverables while tracking performance.",
    example: "A software development team codes, tests, and deploys a new feature based on the approved LMS project plan."
  },
  {
    category: "Integration Management",
    question: "Manage Project Knowledge",
    answer: "The process of leveraging existing knowledge and creating new knowledge to improve project outcomes.",
    example: "A project team reviews past LMS development projects to apply best practices and avoid previous mistakes."
  },
  {
    category: "Integration Management",
    question: "Monitor and Control Project Work",
    answer: "The process of tracking project performance and making necessary adjustments to meet objectives.",
    example: "A project manager monitors task completion rates and adjusts resources to prevent delays in a certification course rollout."
  },
  {
    category: "Integration Management",
    question: "Perform Integrated Change Control",
    answer: "The process of reviewing, approving, and managing changes to project scope, schedule, and cost.",
    example: "A change request to add AI-powered quizzes to an LMS goes through an approval process to assess its impact on schedule and budget."
  },
  {
    category: "Integration Management",
    question: "Close Project or Phase",
    answer: "The process of finalizing all project activities and ensuring deliverables are accepted.",
    example: "After launching an LMS, the project manager ensures all contracts are closed and documents are archived."
  },

  // Scope Management Flashcards
  {
    category: "Scope Management",
    question: "Project Scope Management",
    answer: "The process of defining, controlling, and managing what is included and excluded from the project.",
    example: "In an LMS project, scope management ensures that the system includes video streaming and quizzes but does not include live tutoring."
  },
  {
    category: "Scope Management",
    question: "Product Scope vs. Project Scope",
    answer: "Product Scope refers to features and functionalities of the deliverable. Project Scope refers to the work required to deliver the product.",
    example: "In an e-learning platform, the product scope includes course videos and assessments, while the project scope includes designing, developing, and testing the platform."
  },
  {
    category: "Scope Management",
    question: "Scope Management Plan",
    answer: "A document that defines how the project scope will be planned, validated, and controlled.",
    example: "A scope management plan for an LMS outlines how new course features will be reviewed and approved before implementation."
  },
  {
    category: "Scope Management",
    question: "Collect Requirements",
    answer: "The process of gathering stakeholder needs and expectations to define project scope.",
    example: "Before developing a certification training app, interviews with students and trainers help collect requirements for course progress tracking and interactive quizzes."
  },
  {
    category: "Scope Management",
    question: "Define Scope",
    answer: "The process of creating a detailed description of the project and product scope.",
    example: "A defined scope for an online certification prep course includes video lessons, practice tests, and a certificate generator."
  },
  {
    category: "Scope Management",
    question: "Work Breakdown Structure (WBS)",
    answer: "A hierarchical decomposition of project deliverables into smaller, manageable components.",
    example: "A WBS for a PMP course development project may have levels for content creation, video recording, editing, and course upload."
  },
  {
    category: "Scope Management",
    question: "Validate Scope",
    answer: "The process of formal acceptance of completed deliverables by stakeholders.",
    example: "Before launching a mock exam website, stakeholders review and approve the exam question bank and automated scoring feature."
  },
  {
    category: "Scope Management",
    question: "Control Scope",
    answer: "The process of monitoring scope and managing changes to prevent scope creep.",
    example: "A project manager rejects a request to add gamification features in an LMS because it was not part of the approved scope."
  },
  {
    category: "Scope Management",
    question: "Scope Creep",
    answer: "The uncontrolled expansion of project scope without proper approval.",
    example: "A project initially planned to develop a basic test-taking app, but later, stakeholders request chat support, AI analytics, and certification badges, leading to scope creep."
  },
  {
    category: "Scope Management",
    question: "Gold Plating",
    answer: "The practice of adding extra features or work beyond what is required, leading to wasted resources.",
    example: "A developer adds an animated introduction to an LMS that was not requested by stakeholders, causing unnecessary delays."
  },
  {
    category: "Scope Management",
    question: "Scope Baseline",
    answer: "The approved version of the scope statement, WBS, and WBS dictionary, used as a reference for project performance.",
    example: "If the original LMS project scope baseline does not include AI-generated quizzes, any such addition must go through formal change control."
  },
  {
    category: "Scope Management",
    question: "Change Requests (Scope Changes)",
    answer: "A formal request to modify the project scope, which goes through an approval process.",
    example: "The training team requests adding subtitles to all course videos, requiring a formal change request."
  },
  {
    category: "Scope Management",
    question: "Product Backlog (Agile Scope Management)",
    answer: "A list of prioritized project features and tasks that are refined throughout the project.",
    example: "In Agile LMS development, the backlog includes user profiles, course recommendations, and quiz analytics, prioritized by value."
  },
  {
    category: "Scope Management",
    question: "Rolling Wave Planning",
    answer: "A planning approach where detailed planning is done for the near term, while future tasks are planned at a high level.",
    example: "In an Agile LMS project, week 1 tasks are defined in detail, while future course modules remain broadly outlined until development approaches."
  },
  {
    category: "Schedule Management",
    question: "Project Schedule Management",
    answer: "The process of defining, managing, and controlling the project timeline to ensure timely completion.",
    example: "A project manager creates a detailed timeline for an LMS development project, scheduling content creation, testing, and launch milestones."
  },
  {
    category: "Schedule Management",
    question: "Define Activities",
    answer: "The process of identifying and listing all tasks required to complete project deliverables.",
    example: "For an online course project, activities include scriptwriting, video recording, editing, and uploading."
  },
  {
    category: "Schedule Management",
    question: "Activity List",
    answer: "A detailed list of all activities required for project completion.",
    example: "A project activity list for a learning app development includes tasks like database setup, front-end design, and content integration."
  },
  {
    category: "Schedule Management",
    question: "Estimate Activity Durations",
    answer: "The process of predicting how long each task will take based on historical data and expert judgment.",
    example: "Video production for a PMP course module is estimated to take four weeks based on past projects."
  },
  {
    category: "Schedule Management",
    question: "Critical Path Method (CPM)",
    answer: "A technique that identifies the longest path of dependent tasks to determine the minimum project duration.",
    example: "In a mock exam system project, 'Develop Backend → Integrate Payment → Test System' forms the critical path, as delays in these tasks will extend the project timeline."
  },
  {
    category: "Schedule Management",
    question: "Float (Slack Time)",
    answer: "The amount of time an activity can be delayed without affecting the overall project timeline.",
    example: "If 'Update User Interface' has 5 days of float, it can be delayed by up to 5 days without impacting the launch date."
  },
  {
    category: "Schedule Management",
    question: "Crashing",
    answer: "A schedule compression technique that adds more resources (e.g., manpower, equipment, overtime) to reduce project duration without changing the project scope.",
    example: "In a software development project, hiring additional developers to speed up coding and testing phases to meet an earlier deadline."
  },
  {
    category: "Schedule Management",
    question: "Fast-Tracking",
    answer: "A technique where activities that were originally scheduled sequentially are performed in parallel to shorten project duration.",
    example: "In a construction project, starting interior design work while exterior walls are still being built, instead of waiting for wall completion."
  },
  {
    category: "Schedule Management",
    question: "Agile Scheduling",
    answer: "A flexible scheduling approach where work is planned in iterations (sprints) rather than a fixed timeline.",
    example: "An Agile team plans two-week sprints to develop features for a learning management system."
  },
  {
    category: "Schedule Management",
    question: "Milestone List",
    answer: "A document that tracks key project checkpoints and their deadlines.",
    example: "Milestones for a certification website include 'Beta Version Ready' (March 15) and 'Public Launch' (May 1)."
  },
  {
    category: "Schedule Management",
    question: "Gantt Chart",
    answer: "A visual project schedule that displays activities, timelines, and dependencies.",
    example: "A Gantt chart for an LMS project shows video production tasks spanning February to March."
  },
  {
    category: "Schedule Management",
    question: "Resource Leveling",
    answer: "Adjusting the project schedule to balance resource allocation and avoid overloading team members.",
    example: "If multiple developers are assigned too many parallel tasks, resource leveling spreads out deadlines to reduce workload stress."
  },
  {
    category: "Schedule Management",
    question: "Schedule Monitoring & Variance Analysis",
    answer: "The process of comparing actual progress against the schedule baseline to detect deviations.",
    example: "If course video production is two weeks behind schedule, variance analysis helps identify the cause and corrective action."
  }, {
    category: "Cost Management",
    question: "Project Cost Management",
    answer: "The process of estimating, budgeting, and controlling costs to ensure the project is completed within the approved budget.",
    example: "A project manager monitors development costs for an LMS to ensure they stay within the $50,000 budget."
  },
  {
    category: "Cost Management",
    question: "Cost Management Plan",
    answer: "A document that outlines how project costs will be estimated, managed, and controlled.",
    example: "A cost management plan for a PMP certification website includes budget approval steps, tracking methods, and contingency reserves."
  },
  {
    category: "Cost Management",
    question: "Estimate Costs",
    answer: "The process of predicting the financial resources needed for each project activity.",
    example: "The cost estimate for developing a mobile learning app includes $20,000 for development, $10,000 for content creation, and $5,000 for testing."
  },
  {
    category: "Cost Management",
    question: "Analogous Estimating",
    answer: "A technique that uses historical data from similar past projects to estimate cost, duration, or resources.",
    example: "If a previous mobile app project took 6 months and $50,000, a similar new project might be estimated to take the same."
  },
  {
    category: "Cost Management",
    question: "Parametric Estimating",
    answer: "A technique that applies statistical relationships (unit rates) to project variables for estimation.",
    example: "If painting a wall costs $5 per square meter, and a room has 200 square meters, then the estimated cost = $1,000."
  },
  {
    category: "Cost Management",
    question: "Bottom-Up Estimating",
    answer: "A technique where costs and durations are estimated for each individual task or work package and summed up.",
    example: "An LMS project estimates: Course design ($5,000), Video production ($10,000), Platform development ($20,000), Marketing ($7,000). Total = $42,000."
  },
  {
    category: "Cost Management",
    question: "Three-Point Estimating",
    answer: "A method that calculates the expected cost or duration using three values: Optimistic (O), Pessimistic (P), and Most Likely (M).",
    example: "If a construction task has: O = 5 days, M = 8 days, P = 12 days, Using PERT formula: (5 + 4(8) + 12) / 6 = 8.17 days."
  },
  {
    category: "Cost Management",
    question: "Determine Budget",
    answer: "The process of aggregating estimated costs to establish an approved project budget.",
    example: "The total approved budget for a corporate training program includes software licensing, instructor fees, and marketing expenses."
  },
  {
    category: "Cost Management",
    question: "Cost Baseline",
    answer: "The approved version of the project budget, used to track actual spending.",
    example: "The LMS project’s cost baseline is $100,000, and any spending above this amount requires formal approval."
  },
  {
    category: "Cost Management",
    question: "Direct Costs vs. Indirect Costs",
    answer: "Direct Costs are expenses traced directly to the project, while Indirect Costs are shared across multiple projects.",
    example: "Hosting fees for an e-learning website are direct costs, while IT support services are indirect costs."
  },
  {
    category: "Cost Management",
    question: "Control Costs",
    answer: "The process of monitoring project expenses and controlling deviations from the budget.",
    example: "A project manager reviews financial reports to identify unexpected overspending on software licenses."
  },
  {
    category: "Cost Management",
    question: "Earned Value Management (EVM)",
    answer: "A technique for measuring project performance based on scope, schedule, and cost.",
    example: "If the earned value (EV) of an LMS project is lower than planned value (PV), the project is behind schedule."
  },
  {
    category: "Cost Management",
    question: "Cost Variance (CV)",
    answer: "A measure of budget performance, calculated as CV = Earned Value (EV) - Actual Cost (AC).",
    example: "If the EV of a course development project is $10,000, but the AC is $12,000, the project is over budget by $2,000."
  },
  {
    category: "Cost Management",
    question: "Cost Performance Index (CPI)",
    answer: "A ratio that measures cost efficiency, calculated as CPI = EV / AC.",
    example: "A CPI of 1.2 means the project is running efficiently and spending less than planned."
  },
  {
    category: "Cost Management",
    question: "Planned Value (PV)",
    answer: "The budgeted cost for planned work at a given point in time.",
    example: "By month 3, the planned value for an LMS project is $40,000, meaning this amount should be spent if work is on track."
  },
  {
    category: "Cost Management",
    question: "Actual Cost (AC)",
    answer: "The amount actually spent on project activities.",
    example: "If a company spent $60,000 on an LMS project so far, that is its actual cost (AC)."
  },
  {
    category: "Cost Management",
    question: "Forecasting (Estimate at Completion - EAC)",
    answer: "A calculation used to predict total project cost at completion, based on current performance.",
    example: "If cost overruns occur, the new estimate for total project cost increases from $100,000 to $120,000."
  },
  {
    category: "Cost Management",
    question: "Reserve Analysis",
    answer: "The process of assessing contingency and management reserves to handle unexpected costs.",
    example: "A project sets aside a 10% contingency reserve for unexpected LMS development challenges."
  },
  {
    category: "Cost Management",
    question: "Contingency Reserve",
    answer: "Funds allocated for identified risks that may or may not occur.",
    example: "A budget for software testing issues is a contingency reserve."
  },
  {
    category: "Cost Management",
    question: "Management Reserve",
    answer: "Funds set aside for unknown risks.",
    example: "A reserve for unexpected regulatory changes is a management reserve."
  },
  {
    category: "Cost Management",
    question: "Life Cycle Costing",
    answer: "A method that considers the total cost of a project, including operational and maintenance costs.",
    example: "The total cost of an LMS platform includes initial development, cloud hosting, and long-term maintenance."
  },
  {
    category: "Cost Management",
    question: "Value Engineering",
    answer: "A technique that optimizes cost without reducing project quality.",
    example: "Instead of building a custom video player, an LMS project integrates YouTube APIs, reducing costs without sacrificing quality."
  },{
    category: "Quality Management",
    question: "Project Quality Management",
    answer: "The process of ensuring that project deliverables meet stakeholder expectations and predefined quality standards.",
    example: "A project manager ensures that an LMS platform's video content meets HD resolution and clear audio requirements."
  },
  {
    category: "Quality Management",
    question: "Quality Management Plan",
    answer: "A document that outlines quality objectives, quality standards, and quality control measures for the project.",
    example: "A quality management plan for an e-learning platform specifies that course materials must be reviewed for accuracy before release."
  },
  {
    category: "Quality Management",
    question: "Plan Quality Management",
    answer: "The process of identifying quality requirements and determining how they will be met.",
    example: "A project team defines a checklist for video editing to ensure uniform branding, sound clarity, and subtitle accuracy."
  },
  {
    category: "Quality Management",
    question: "Manage Quality (Quality Assurance)",
    answer: "The process of proactively reviewing project processes to ensure quality standards are being followed.",
    example: "A QA team regularly audits coding practices to maintain consistency and security in an LMS development project."
  },
  {
    category: "Quality Management",
    question: "Control Quality (Quality Control - QC)",
    answer: "The process of monitoring and inspecting deliverables to ensure they meet quality standards.",
    example: "Testers review LMS system performance, ensuring that course videos load without buffering issues."
  },
  {
    category: "Quality Management",
    question: "Quality Metrics",
    answer: "Measurable indicators used to evaluate project quality.",
    example: "A quality metric for an online course could be 'Customer satisfaction score above 90%'."
  },
  {
    category: "Quality Management",
    question: "Cost of Quality (COQ)",
    answer: "The total cost associated with ensuring, preventing, and fixing quality issues.",
    example: "A company investing in automated testing tools for LMS development reduces long-term bug-fixing costs."
  },
  {
    category: "Quality Management",
    question: "Cost of Conformance",
    answer: "Money spent on preventing defects (e.g., training, testing).",
    example: "Investing in proper course content validation (cost of conformance)."
  },
  {
    category: "Quality Management",
    question: "Cost of Non-Conformance",
    answer: "Money spent on fixing defects (e.g., rework, customer complaints).",
    example: "Refunds and complaints due to inaccurate course content (cost of non-conformance)."
  },
  {
    category: "Quality Management",
    question: "Prevention vs. Inspection",
    answer: "Prevention ensures defects don’t occur, while Inspection finds and fixes defects after they occur.",
    example: "Testing videos before uploading (prevention) vs. post-release re-editing (inspection)."
  },
  {
    category: "Quality Management",
    question: "Continuous Improvement (Kaizen)",
    answer: "A philosophy of ongoing efforts to enhance project quality and processes.",
    example: "A software team regularly reviews coding practices and applies new improvements to maintain efficiency."
  },
  {
    category: "Quality Management",
    question: "Total Quality Management (TQM)",
    answer: "A company-wide approach to long-term success through continuous quality improvement.",
    example: "An EdTech company adopts TQM by involving instructors, students, and developers in the quality feedback process."
  },
  {
    category: "Quality Management",
    question: "Six Sigma",
    answer: "A data-driven quality management methodology that aims to reduce defects to 3.4 per million opportunities.",
    example: "A company applies Six Sigma techniques to minimize student login failures in an LMS."
  },
  {
    category: "Quality Management",
    question: "DMAIC (Six Sigma Methodology)",
    answer: "A structured Six Sigma process that includes Define, Measure, Analyze, Improve, and Control.",
    example: "An Agile team uses DMAIC to optimize student enrollment processes in an LMS."
  },
  {
    category: "Quality Management",
    question: "Statistical Process Control (SPC)",
    answer: "The use of statistical methods to monitor and control process variations.",
    example: "A project team tracks video upload success rates using control charts to identify inconsistencies."
  },
  {
    category: "Quality Management",
    question: "Control Charts",
    answer: "Graphical tools that help monitor process stability and identify deviations.",
    example: "A control chart for student quiz scores shows consistent performance or abnormal variations."
  },
  {
    category: "Quality Management",
    question: "Benchmarking",
    answer: "The process of comparing project performance against industry best practices.",
    example: "A project team studies top-rated e-learning platforms to improve their own user experience."
  },
  {
    category: "Quality Management",
    question: "Pareto Principle (80/20 Rule)",
    answer: "A principle stating that 80% of problems come from 20% of causes.",
    example: "A team discovers that most student complaints are due to slow video buffering, so they prioritize fixing it."
  },
  {
    category: "Quality Management",
    question: "Ishikawa Diagram (Fishbone Diagram)",
    answer: "A tool used to identify the root cause of quality issues.",
    example: "A project team uses an Ishikawa diagram to analyze why some students drop out of an online course."
  },
  {
    category: "Quality Management",
    question: "Histogram",
    answer: "A bar chart used to analyze the frequency of defects or issues.",
    example: "A histogram shows that most LMS login issues occur during peak hours, indicating server overload."
  },
  {
    category: "Quality Management",
    question: "Failure Mode and Effects Analysis (FMEA)",
    answer: "A technique used to identify potential failures and their impact before they occur.",
    example: "A team conducts FMEA on a new exam system to anticipate possible scoring calculation errors."
  },
  {
    category: "Quality Management",
    question: "Quality Audits",
    answer: "A structured review to ensure processes align with quality standards.",
    example: "A project auditor reviews whether an LMS development team is following Agile best practices."
  },
  {
    category: "Quality Management",
    question: "Design of Experiments (DOE)",
    answer: "A statistical approach used to test multiple variables and determine the best combination for quality improvement.",
    example: "A UX design team tests different layouts for an LMS dashboard to determine which enhances student engagement."
  },
  {
    category: "Quality Management",
    question: "Just-in-Time (JIT) Quality Management",
    answer: "A methodology that focuses on minimizing waste and producing only what is needed.",
    example: "A video production team records content only after scripts are finalized, reducing unnecessary revisions."
  },{
    category: "Resource Management",
    question: "Project Resource Management",
    answer: "The process of identifying, acquiring, and managing the physical and human resources needed for project success.",
    example: "A project manager allocates instructors, developers, and video editors for an LMS development project."
  },
  {
    category: "Resource Management",
    question: "Resource Management Plan",
    answer: "A document outlining how project resources will be acquired, allocated, managed, and released.",
    example: "A resource management plan for a corporate training program details how instructors and IT support staff will be assigned."
  },
  {
    category: "Resource Management",
    question: "Estimate Activity Resources",
    answer: "The process of determining the type and quantity of resources needed for project activities.",
    example: "To build an e-learning platform, the team estimates needing 3 developers, 2 content creators, and 1 UI/UX designer."
  },
  {
    category: "Resource Management",
    question: "Acquire Resources",
    answer: "The process of obtaining human, material, and equipment resources required for the project.",
    example: "Before launching a PMP prep course, the team hires instructors, video editors, and a graphic designer."
  },
  {
    category: "Resource Management",
    question: "Develop Team",
    answer: "The process of enhancing team competencies, improving interactions, and increasing overall team effectiveness.",
    example: "The project team attends an Agile workshop to improve collaboration on a software development project."
  },
  {
    category: "Resource Management",
    question: "Manage Team",
    answer: "The process of tracking team performance, resolving conflicts, and motivating team members.",
    example: "A project manager mediates a conflict between developers and testers to ensure smooth execution."
  },
  {
    category: "Resource Management",
    question: "Control Resources",
    answer: "The process of ensuring that project resources are available, allocated properly, and used efficiently.",
    example: "The project manager tracks equipment usage to prevent overuse of video editing software licenses."
  },
  {
    category: "Resource Management",
    question: "Resource Breakdown Structure (RBS)",
    answer: "A hierarchical chart that categorizes resources by type (human, material, equipment, etc.).",
    example: "An LMS project’s RBS includes developers, graphic designers, cloud storage, and recording equipment."
  },
  {
    category: "Resource Management",
    question: "Organizational Theory",
    answer: "The study of how teams and organizations behave, influencing how resources are managed.",
    example: "A project manager applies motivation theories like Maslow’s Hierarchy of Needs to improve team productivity."
  },
  {
    category: "Resource Management",
    question: "Responsibility Assignment Matrix (RAM)",
    answer: "A chart that maps team members to specific project tasks and responsibilities.",
    example: "A RAM for a mock exam platform assigns developers to coding tasks, testers to QA, and instructors to content creation."
  },
  {
    category: "Resource Management",
    question: "RACI Chart",
    answer: "A specific type of RAM that defines who is Responsible, Accountable, Consulted, and Informed for each task.",
    example: "In a PMP training program, the trainer is Responsible for course delivery, the project manager is Accountable, and marketing is Consulted."
  },
  {
    category: "Resource Management",
    question: "Team Charter",
    answer: "A document outlining team values, roles, communication guidelines, and conflict resolution methods.",
    example: "A software team’s charter establishes weekly meetings and a process for resolving coding disagreements."
  },
  {
    category: "Resource Management",
    question: "Resource Allocation",
    answer: "The process of assigning available resources to project tasks based on needs and priorities.",
    example: "A project manager assigns more developers to a critical sprint to meet a software launch deadline."
  },
  {
    category: "Resource Management",
    question: "Virtual Teams",
    answer: "Teams that work remotely across different locations using digital communication tools.",
    example: "A global project team developing an LMS collaborates via Zoom, Slack, and Google Docs."
  },
  {
    category: "Resource Management",
    question: "Resource Leveling",
    answer: "A technique used to adjust the project schedule to balance resource demand and availability.",
    example: "If too many developers are assigned to parallel tasks, resource leveling staggers deadlines to prevent burnout."
  },
  {
    category: "Resource Management",
    question: "Resource Smoothing",
    answer: "A technique that ensures resource demand does not exceed availability, without changing the critical path.",
    example: "The project team distributes content development over several weeks to avoid overloading instructors."
  },
  {
    category: "Procurement Management",
    question: "Project Procurement Management",
    answer: "The process of acquiring goods, services, or results from external vendors or suppliers to support project needs.",
    example: "A company outsources content creation for an online PMP training course due to a lack of in-house expertise."
  },
  {
    category: "Procurement Management",
    question: "Procurement Management Plan",
    answer: "A document that defines how project procurement activities will be managed, including vendor selection, contract types, and risk handling.",
    example: "A procurement management plan for a software development project details how third-party API integrations will be sourced and managed."
  },
  {
    category: "Procurement Management",
    question: "Procurement Strategy",
    answer: "The approach used to select suppliers, define contract terms, and manage procurement risks.",
    example: "A company chooses between hiring in-house developers or outsourcing LMS development based on cost and expertise."
  },
  {
    category: "Procurement Management",
    question: "Make-or-Buy Analysis",
    answer: "A decision-making process used to determine whether to develop a product/service internally or purchase it from an external provider.",
    example: "A startup decides to buy a third-party video hosting service instead of building its own to save time and resources."
  },
  {
    category: "Procurement Management",
    question: "Source Selection Criteria",
    answer: "The standards used to evaluate and select vendors, such as cost, quality, experience, and reliability.",
    example: "A project team selects a cloud hosting provider based on uptime guarantees, scalability, and pricing."
  },
  {
    category: "Procurement Management",
    question: "Procurement Documents",
    answer: "Documents used to solicit vendor proposals, such as RFPs (Request for Proposal), RFQs (Request for Quotation), and IFBs (Invitation for Bid).",
    example: "A company issues an RFP to multiple LMS vendors to find the best e-learning platform provider."
  },
  {
    category: "Procurement Management",
    question: "Bidder Conference (Pre-Bid Meeting)",
    answer: "A meeting where potential vendors ask questions and clarify requirements before submitting bids.",
    example: "Before selecting a website development contractor, a company hosts a bidder conference to answer vendor questions about the project scope."
  },
  {
    category: "Procurement Management",
    question: "Proposal Evaluation Techniques",
    answer: "Methods used to review and compare vendor proposals, such as weighted scoring models and cost-benefit analysis.",
    example: "A company assigns scores to different LMS providers based on pricing, feature set, and past performance."
  },
  {
    category: "Procurement Management",
    question: "Fixed-Price Contracts (FP)",
    answer: "A contract where the vendor provides a service or product at a predetermined fixed cost, regardless of actual expenses incurred.",
    example: "A company hires a contractor to build a website for $50,000, with no additional costs allowed."
  },
  {
    category: "Procurement Management",
    question: "Cost-Reimbursable Contracts (CR)",
    answer: "A contract where the vendor is reimbursed for actual costs incurred plus an additional fee or profit.",
    example: "A research firm is reimbursed for all costs related to a clinical trial, plus a 10% management fee."
  },
  {
    category: "Procurement Management",
    question: "Time and Material Contracts (T&M)",
    answer: "A contract where the vendor charges based on the time worked and materials used, with no fixed total cost.",
    example: "A company hires IT consultants at $100 per hour, plus hardware costs as needed."
  },
  {
    category: "Procurement Management",
    question: "Firm Fixed Price (FFP) Contract",
    answer: "A contract where the price is predetermined and does not change regardless of costs incurred.",
    example: "A website redesign is contracted at $50,000, meaning the vendor must complete it within this budget."
  },
  {
    category: "Procurement Management",
    question: "Cost Plus Fixed Fee (CPFF) Contract",
    answer: "A contract where the buyer reimburses costs and pays a fixed fee to the seller.",
    example: "A software firm reimburses a development agency for project expenses and pays an additional $5,000 as a fixed fee."
  },
  {
    category: "Procurement Management",
    question: "Cost Plus Incentive Fee (CPIF) Contract",
    answer: "A contract where the seller is reimbursed for costs plus an incentive fee for meeting performance targets.",
    example: "A vendor gets a bonus if they complete an LMS deployment 10% under budget."
  },
  {
    category: "Procurement Management",
    question: "Conduct Procurements",
    answer: "The process of soliciting bids, evaluating proposals, and awarding contracts.",
    example: "A university reviews proposals from multiple e-learning platform providers and selects the best fit."
  },
  {
    category: "Procurement Management",
    question: "Seller Selection",
    answer: "The process of choosing the most suitable vendor based on evaluation criteria.",
    example: "A company chooses a software firm for LMS development based on experience and cost-effectiveness."
  },
  {
    category: "Procurement Management",
    question: "Procurement Negotiation",
    answer: "The process of discussing terms, costs, and conditions with vendors before signing a contract.",
    example: "A project team negotiates with a vendor to reduce costs by adjusting the project scope."
  },
  {
    category: "Procurement Management",
    question: "Control Procurements",
    answer: "The process of monitoring vendor performance and ensuring contract compliance.",
    example: "A project manager reviews monthly progress reports to ensure an outsourced course development project stays on track."
  },
  {
    category: "Procurement Management",
    question: "Performance Reviews (Vendor Audits)",
    answer: "Regular assessments of vendor performance against contract terms.",
    example: "A company reviews an LMS provider’s service uptime to confirm they meet their 99.9% availability guarantee."
  },
  {
    category: "Procurement Management",
    question: "Procurement Risk Management",
    answer: "The process of identifying and mitigating risks related to vendor relationships and contracts.",
    example: "A project team assesses the risk of a supplier failing to deliver materials on time and sets up backup options."
  },
  {
    category: "Procurement Management",
    question: "Contract Change Control System",
    answer: "A process for modifying contract terms and managing contract-related changes.",
    example: "If a vendor requires extra time due to unforeseen delays, a formal change request is submitted and reviewed."
  },
  {
    category: "Procurement Management",
    question: "Procurement Documentation",
    answer: "Records related to contracts, purchase orders, invoices, and vendor correspondence.",
    example: "A company keeps a record of all invoices from an LMS hosting provider for financial tracking."
  },
  {
    category: "Procurement Management",
    question: "Early Termination of Contract",
    answer: "The process of ending a vendor agreement before the contract period ends due to non-performance or project changes.",
    example: "A company terminates a contract with a non-compliant vendor who failed to deliver learning modules on time."
  },
  {
    category: "Procurement Management",
    question: "Procurement Closure",
    answer: "The process of finalizing contracts, ensuring all obligations are met, and documenting lessons learned.",
    example: "A project manager confirms that all deliverables have been received and formally closes the LMS procurement contract."
  },  {
    category: "Stakeholder Management",
    question: "Project Stakeholder Management",
    answer: "The process of identifying, analyzing, engaging, and managing stakeholders to ensure project success.",
    example: "A project manager regularly updates course instructors on LMS development progress to keep them engaged."
  },
  {
    category: "Stakeholder Management",
    question: "Stakeholder",
    answer: "Any individual, group, or organization that can affect or be affected by the project.",
    example: "Stakeholders in a corporate training project include employees, trainers, executives, and IT teams."
  },
  {
    category: "Stakeholder Management",
    question: "Stakeholder Register",
    answer: "A document that lists stakeholders, their roles, influence levels, and engagement strategies.",
    example: "A stakeholder register for an LMS project identifies course creators, software developers, and target students."
  },
  {
    category: "Stakeholder Management",
    question: "Stakeholder Engagement Plan",
    answer: "A document that defines how stakeholders will be involved and communicated with throughout the project.",
    example: "An LMS project plans weekly email updates for instructors and monthly meetings with executives."
  },
  {
    category: "Stakeholder Management",
    question: "Identify Stakeholders",
    answer: "The process of determining all people and organizations impacted by the project and documenting their needs and expectations.",
    example: "A university identifies students, faculty, and IT administrators as key stakeholders in a digital learning transformation project."
  },
  {
    category: "Stakeholder Management",
    question: "Stakeholder Analysis",
    answer: "The process of assessing stakeholder influence, interest, and expectations to determine engagement strategies.",
    example: "A project manager classifies executives as high-influence, high-interest stakeholders needing frequent updates."
  },
  {
    category: "Stakeholder Management",
    question: "Stakeholder Engagement Assessment Matrix",
    answer: "A tool that categorizes stakeholders based on their current and desired engagement levels: Unaware, Resistant, Neutral, Supportive, Leading.",
    example: "A project team moves a key sponsor from ‘Neutral’ to ‘Supportive’ through proactive communication and involvement."
  },
  {
    category: "Stakeholder Management",
    question: "Power/Interest Grid",
    answer: "A tool that classifies stakeholders based on their level of power and interest in the project: High Power/High Interest (Manage Closely), High Power/Low Interest (Keep Satisfied), Low Power/High Interest (Keep Informed), Low Power/Low Interest (Monitor Only).",
    example: "A CEO falls into the ‘Manage Closely’ category, while an external consultant might be in ‘Keep Informed.’"
  },
  {
    category: "Stakeholder Management",
    question: "Plan Stakeholder Engagement",
    answer: "The process of developing a strategy to effectively engage stakeholders throughout the project lifecycle.",
    example: "A project team plans monthly town hall meetings to address student concerns during an LMS rollout."
  },
  {
    category: "Stakeholder Management",
    question: "Manage Stakeholder Engagement",
    answer: "The process of proactively engaging stakeholders to address their concerns and ensure alignment.",
    example: "A project manager personally meets with resistant stakeholders to understand their concerns and adjust strategies accordingly."
  },
  {
    category: "Stakeholder Management",
    question: "Monitor Stakeholder Engagement",
    answer: "The process of tracking stakeholder interactions and adjusting engagement strategies as needed.",
    example: "After receiving negative feedback from instructors, a project team modifies communication methods to provide more real-time updates."
  },
  {
    category: "Stakeholder Management",
    question: "Change Management in Stakeholder Engagement",
    answer: "The process of helping stakeholders adapt to changes introduced by the project.",
    example: "A company provides hands-on training sessions for employees before implementing a new project management tool."
  },
  {
    category: "Stakeholder Management",
    question: "Conflict Resolution in Stakeholder Management",
    answer: "The use of strategies like negotiation, mediation, and compromise to resolve stakeholder disputes.",
    example: "A project manager facilitates discussions between IT and marketing teams to align priorities for a new e-commerce platform."
  },
  {
    category: "Stakeholder Management",
    question: "Communication Strategies for Stakeholders",
    answer: "Different methods of stakeholder communication based on their influence and interest.",
    example: "Executives receive high-level reports, while the project team gets detailed task updates."
  },
  {
    category: "Stakeholder Management",
    question: "Influence of Stakeholders on Project Success",
    answer: "Stakeholders can positively or negatively impact the project, depending on their level of engagement and satisfaction.",
    example: "A supportive sponsor accelerates decision-making, while an unsatisfied vendor may delay deliverables."
  },
  {
    category: "Stakeholder Management",
    question: "Escalation Process for Stakeholder Issues",
    answer: "A structured approach for resolving stakeholder-related conflicts by escalating them through proper channels.",
    example: "If a key vendor refuses to meet deadlines, the issue is escalated to senior management for resolution."
  },
  {
    category: "Stakeholder Management",
    question: "Stakeholder Satisfaction Metrics",
    answer: "Methods used to measure stakeholder engagement and satisfaction, such as surveys and feedback forms.",
    example: "An LMS provider tracks Net Promoter Score (NPS) to assess how satisfied users are with the platform."
  },
  {
    category: "Stakeholder Management",
    question: "Engagement Best Practices",
    answer: "Techniques to maintain positive stakeholder relationships, such as transparency, active listening, and responsiveness.",
    example: "A project team holds informal Q&A sessions to ensure stakeholders feel heard and valued."
  },
  {
    category: "Stakeholder Management",
    question: "Lessons Learned from Stakeholder Engagement",
    answer: "A document capturing what worked and what didn’t in stakeholder communication and engagement.",
    example: "A project team documents that early stakeholder involvement led to smoother project execution."
  },
  {
    category: "Risk Management",
    question: "Project Risk Management",
    answer: "The process of identifying, analyzing, and responding to project risks to minimize negative impacts and maximize opportunities.",
    example: "A project manager identifies potential delays in content production for an LMS and develops mitigation strategies."
  },
  {
    category: "Risk Management",
    question: "Risk Management Plan",
    answer: "A document that outlines how risks will be identified, assessed, monitored, and controlled throughout the project.",
    example: "A risk management plan for an AI-powered mock exam platform details contingency plans for AI-generated errors."
  },
  {
    category: "Risk Management",
    question: "Risk Register",
    answer: "A document that tracks identified risks, their potential impact, response plans, and current status.",
    example: "A risk register for a remote training platform includes risks such as internet outages and platform crashes."
  },
  {
    category: "Risk Management",
    question: "Identify Risks",
    answer: "The process of systematically recognizing potential threats and opportunities that may impact the project.",
    example: "The project team brainstorms risks related to course content accuracy before launching a PMP prep course."
  },
  {
    category: "Risk Management",
    question: "Technical Risks",
    answer: "Risks related to technology, software, hardware, or system failures that impact project success.",
    example: "A critical bug in software prevents system deployment, delaying project timelines."
  },
  {
    category: "Risk Management",
    question: "Operational Risks",
    answer: "Risks arising from process inefficiencies, resource shortages, or workflow disruptions.",
    example: "A key team member resigns during a critical phase, leading to project delays."
  },
  {
    category: "Risk Management",
    question: "Financial Risks",
    answer: "Risks associated with budget overruns, funding shortages, or unexpected financial constraints.",
    example: "A supplier increases material costs mid-project, exceeding the planned budget."
  },
  {
    category: "Risk Management",
    question: "External Risks",
    answer: "Risks originating from market changes, regulatory shifts, economic downturns, or environmental factors.",
    example: "A new government regulation increases compliance costs, impacting project feasibility."
  },
  {
    category: "Risk Management",
    question: "Perform Qualitative Risk Analysis",
    answer: "The process of prioritizing risks based on their probability of occurrence and impact.",
    example: "A team assigns high priority to potential data breaches in an LMS, while minor UI bugs are ranked lower."
  },
  {
    category: "Risk Management",
    question: "Probability and Impact Matrix",
    answer: "A grid-based tool that ranks risks based on their likelihood and potential impact.",
    example: "A server failure that is highly likely and has a severe impact is classified as a critical risk."
  },
  {
    category: "Risk Management",
    question: "Perform Quantitative Risk Analysis",
    answer: "The process of numerically analyzing risk probability and impact using data-driven models.",
    example: "A project team uses Monte Carlo simulations to predict possible cost overruns in LMS development."
  },
  {
    category: "Risk Management",
    question: "Monte Carlo Simulation",
    answer: "A technique that uses random probability distributions to model project risk outcomes.",
    example: "A Monte Carlo simulation estimates project completion times based on different risk scenarios."
  },
  {
    category: "Risk Management",
    question: "Risk Appetite",
    answer: "The level of risk an organization is willing to accept in pursuit of its objectives before taking actions to mitigate it.",
    example: "A startup in the tech industry may have a high-risk appetite, investing aggressively in innovation despite uncertainties."
  },
  {
    category: "Risk Management",
    question: "Risk Tolerance",
    answer: "The degree of variability in risk outcomes that an organization can withstand without significantly impacting its operations or goals.",
    example: "A bank has low risk tolerance for financial losses and sets strict investment limits to avoid volatility."
  },
  {
    category: "Risk Management",
    question: "Avoid (Eliminate the Risk)",
    answer: "Removing the risk entirely by changing the project plan or approach.",
    example: "Choosing a proven technology instead of an untested one to prevent system failures."
  },
  {
    category: "Risk Management",
    question: "Mitigate (Reduce Probability or Impact)",
    answer: "Taking proactive measures to lower the likelihood or severity of a risk.",
    example: "Adding extra testing phases to reduce software bugs before launch."
  },
  {
    category: "Risk Management",
    question: "Transfer (Shift Responsibility)",
    answer: "Shifting the financial or operational impact of a risk to a third party (e.g., insurance, outsourcing).",
    example: "Buying insurance to cover project delays due to natural disasters."
  },
  {
    category: "Risk Management",
    question: "Accept (Acknowledge Without Action)",
    answer: "Recognizing a risk but taking no immediate action to prevent or mitigate it.",
    example: "Proceeding with a project despite minor exchange rate fluctuations."
  },
  {
    category: "Risk Management",
    question: "Exploit (Ensure Opportunity Occurs)",
    answer: "Taking proactive steps to guarantee that a positive opportunity is realized.",
    example: "Assigning the best resources to a high-profile project to ensure success and maximize benefits."
  },
  {
    category: "Risk Management",
    question: "Enhance (Increase Likelihood of Benefit)",
    answer: "Increasing the probability or impact of an opportunity by influencing key factors.",
    example: "Investing in employee training to increase the chances of higher productivity and innovation."
  },
  {
    category: "Risk Management",
    question: "Implement Risk Responses",
    answer: "The process of executing planned actions to address project risks.",
    example: "After identifying a vendor delay risk, a project team switches to an alternative supplier to keep the schedule on track."
  },
  {
    category: "Risk Management",
    question: "Monitor Risks",
    answer: "The process of tracking identified risks, reassessing threats, and evaluating the effectiveness of risk responses.",
    example: "A project manager reviews risk logs weekly to check if planned risk responses are effective."
  },
  {
    category: "Risk Management",
    question: "Contingency Plan",
    answer: "A predefined action plan to address identified risks if they occur.",
    example: "A company sets up backup servers in case the primary system fails."
  },
  {
    category: "Risk Management",
    question: "Fallback Plan",
    answer: "An alternative plan that is executed if the contingency plan fails or is insufficient.",
    example: "If backup servers also fail, switching to a cloud-based emergency infrastructure."
  },
  {
    category: "Risk Management",
    question: "Risk Triggers",
    answer: "Indicators that signal a risk is about to occur or has occurred.",
    example: "A spike in server traffic during an online exam may trigger a potential risk of system failure."
  },
  {
    category: "Risk Management",
    question: "Residual Risks",
    answer: "Risks that remain even after implementing risk response strategies.",
    example: "Even after installing fire sprinklers, there is still a residual risk of minor property damage from fire."
  },
  {
    category: "Risk Management",
    question: "Secondary Risks",
    answer: "New risks that emerge as a result of implementing a risk response strategy.",
    example: "Hiring an external vendor to mitigate workload issues introduces a secondary risk of dependency on third-party performance."
  },
  {
    category: "Communications Management",
    question: "Project Communications Management",
    answer: "The process of planning, managing, and distributing project information effectively to stakeholders.",
    example: "A project manager sends weekly status reports to stakeholders about the progress of an LMS development project."
  },
  {
    category: "Communications Management",
    question: "Communications Management Plan",
    answer: "A document outlining how project communications will be structured, executed, and monitored.",
    example: "The communications management plan for a corporate training program specifies who receives project updates and how often."
  },
  {
    category: "Communications Management",
    question: "Plan Communications Management",
    answer: "The process of identifying stakeholder information needs and developing a communication strategy.",
    example: "A project team decides to use email for formal reports and Slack for daily updates in an Agile software project."
  },
  {
    category: "Communications Management",
    question: "Manage Communications",
    answer: "The process of distributing, collecting, storing, and retrieving project information in a timely manner.",
    example: "A project manager hosts bi-weekly team meetings and updates project dashboards to ensure clear communication."
  },
  {
    category: "Communications Management",
    question: "Monitor Communications",
    answer: "The process of ensuring that project information is effectively received, understood, and acted upon.",
    example: "A project manager reviews feedback from stakeholders to adjust reporting frequency and content."
  },
  {
    category: "Communications Management",
    question: "Push Communication",
    answer: "A communication method where information is sent to specific recipients without requiring immediate feedback.",
    example: "A project manager emails a weekly project status report to stakeholders."
  },
  {
    category: "Communications Management",
    question: "Pull Communication",
    answer: "A communication method where information is made available for recipients to access when needed.",
    example: "A company uploads project documents to a shared drive for team members to review at their convenience."
  },
  {
    category: "Communications Management",
    question: "Interactive Communication",
    answer: "A communication method involving real-time, two-way exchanges of information.",
    example: "A video conference between a project team and stakeholders to discuss project risks and solutions."
  },
  {
    category: "Communications Management",
    question: "Communication Channels Formula",
    answer: "The formula n(n-1)/2 determines the number of potential communication channels in a project.",
    example: "A project with 6 team members has 6(6-1)/2 = 15 communication channels, emphasizing the need for structured communication."
  },
  {
    category: "Communications Management",
    question: "Stakeholder Communication Requirements",
    answer: "The specific communication preferences, expectations, and needs of project stakeholders.",
    example: "Executives prefer monthly financial reports, while the development team prefers daily standups."
  },
  {
    category: "Communications Management",
    question: "Communication Technology",
    answer: "The tools used to facilitate project communication, including email, chat platforms, and video conferencing.",
    example: "A remote project team uses Slack for daily communication and Zoom for weekly check-ins."
  },
  {
    category: "Communications Management",
    question: "Communication Barriers",
    answer: "Factors that hinder effective communication, such as cultural differences, technical issues, or unclear messaging.",
    example: "A language barrier between a US-based team and an offshore development team causes misunderstandings in requirement discussions."
  },
  {
    category: "Communications Management",
    question: "Communication Models",
    answer: "Frameworks that describe how messages are sent, received, and processed in a project setting.",
    example: "The Shannon-Weaver Model explains that communication consists of a sender, message, medium, receiver, and feedback loop."
  },
  {
    category: "Communications Management",
    question: "Sender-Receiver Model",
    answer: "A communication model describing how a sender encodes a message and a receiver decodes it, with feedback ensuring understanding.",
    example: "A project manager sends an email update, and the recipient responds with questions for clarification."
  },
  {
    category: "Communications Management",
    question: "Active Listening",
    answer: "The practice of fully focusing, understanding, and responding to the speaker to improve communication effectiveness.",
    example: "During a stakeholder meeting, the project manager paraphrases key points to confirm understanding."
  },
  {
    category: "Communications Management",
    question: "Feedback Mechanisms",
    answer: "Methods for ensuring that messages are correctly understood and acted upon.",
    example: "A team conducts surveys after sprint reviews to gather feedback on communication effectiveness."
  },
  {
    category: "Communications Management",
    question: "Meeting Management",
    answer: "The process of planning, conducting, and following up on meetings to ensure efficiency.",
    example: "A Scrum Master prepares an agenda and action items before a sprint planning meeting."
  },
  {
    category: "Communications Management",
    question: "Kickoff Meeting",
    answer: "An official meeting that marks the start of a project, aligning stakeholders on objectives, scope, roles, and expectations.",
    example: "A project manager hosts a kickoff meeting with the team and sponsors to discuss the project roadmap and deliverables."
  },
  {
    category: "Communications Management",
    question: "Status Meeting",
    answer: "A recurring meeting to provide progress updates, address roadblocks, and ensure alignment with project goals.",
    example: "A weekly status meeting where team members report completed tasks, upcoming work, and any project challenges."
  },
  {
    category: "Communications Management",
    question: "Review Meeting",
    answer: "A session held to evaluate completed work, verify deliverables, and ensure alignment with project requirements.",
    example: "A design review meeting to assess whether UI mockups meet client expectations before moving to development."
  },
  {
    category: "Communications Management",
    question: "Retrospective Meeting",
    answer: "A meeting conducted at the end of a project or sprint to reflect on successes, challenges, and lessons learned.",
    example: "An Agile team holds a sprint retrospective to discuss what went well, what didn’t, and how to improve the next sprint."
  },
  {
    category: "Communications Management",
    question: "Performance Reporting",
    answer: "The process of collecting and sharing data on project progress, issues, and forecasts.",
    example: "A project team uses dashboards to visualize task completion rates and cost trends."
  },
  {
    category: "Communications Management",
    question: "Conflict Resolution Through Communication",
    answer: "Using clear communication strategies to resolve misunderstandings and conflicts in a project team.",
    example: "A project manager facilitates a discussion between a developer and a tester to address conflicting priorities."
  },
  {
    category: "Communications Management",
    question: "Crisis Communication Plan",
    answer: "A predefined strategy for communicating during emergencies or unexpected project challenges.",
    example: "If an LMS server crashes during an online exam, the team follows a crisis communication plan to update users."
  },
  {
    category: "Communications Management",
    question: "Legal and Ethical Considerations in Communication",
    answer: "Ensuring project communications follow legal guidelines, confidentiality policies, and ethical standards.",
    example: "A healthcare project ensures that patient data shared in reports complies with HIPAA regulations."
  }
];




export default KnowledgeAreaData;
