const PerformanceDomainData = [
    {
        
            category: "Teams",
            question: "Teams Performance Domain",
            answer: "A domain that focuses on building, developing, and managing teams to maximize collaboration and effectiveness.",
            example: "A project manager implements Agile ceremonies to improve communication among cross-functional teams."
        },
        {
            category: "Teams",
            question: "Project Team",
            answer: "A group of individuals with diverse skills and responsibilities working towards a common project goal.",
            example: "A team developing an LMS platform consists of UI/UX designers, developers, content creators, and QA testers."
        },
        {
            category: "Teams",
            question: "High-Performing Teams",
            answer: "Teams that demonstrate strong collaboration, accountability, innovation, and continuous improvement.",
            example: "A high-performing Scrum team delivers high-quality product increments every sprint without missing deadlines."
        },
        {
            category: "Teams",
            question: "Team Charter",
            answer: "A document that defines team values, roles, responsibilities, communication guidelines, and decision-making processes.",
            example: "A remote development team creates a team charter specifying daily stand-up meetings and preferred communication tools."
        },
        {
            category: "Teams",
            question: "Servant Leadership",
            answer: "A leadership style where the leader prioritizes team members' growth, development, and well-being.",
            example: "A Scrum Master removes obstacles and supports team autonomy instead of micromanaging tasks."
        },
        {
            category: "Teams",
            question: "Emotional Intelligence (EI)",
            answer: "The ability to understand and manage emotions in oneself and others to improve team collaboration.",
            example: "A project manager remains calm during conflicts and helps resolve disagreements constructively."
        },
        {
            category: "Teams",
            question: "Psychological Safety",
            answer: "A team environment where members feel safe to express ideas, ask questions, and admit mistakes without fear of criticism.",
            example: "A team encourages open discussions, allowing junior developers to share innovative ideas without hesitation."
        },
        {
            category: "Teams",
            question: "Team Building Activities",
            answer: "Exercises that enhance trust, communication, and collaboration among team members.",
            example: "A project team engages in a virtual escape room challenge to build camaraderie among remote employees."
        },
        {
            category: "Teams",
            question: "Collaboration and Knowledge Sharing",
            answer: "The practice of working together, sharing expertise, and learning from one another to achieve project success.",
            example: "A development team holds weekly knowledge-sharing sessions to discuss new coding techniques."
        },
        {
            category: "Teams",
            question: "Team Performance Assessments",
            answer: "A process of evaluating team effectiveness, productivity, and satisfaction to improve performance.",
            example: "A Scrum team reviews velocity and retrospective feedback to identify areas for improvement."
        },
        {
            category: "Teams",
            question: "Virtual Teams",
            answer: "Teams that collaborate remotely using digital communication tools instead of working in a shared location.",
            example: "A global team uses Slack, Zoom, and Trello to coordinate tasks and meetings."
        },
        {
            category: "Teams",
            question: "Role of a Scrum Master in a Team",
            answer: "A Scrum Master facilitates Agile processes, removes impediments, and ensures the team follows Scrum principles.",
            example: "A Scrum Master helps a team resolve a bottleneck by prioritizing backlog refinement and clarifying requirements."
        },
        {
            category: "Teams",
            question: "Role of a Product Owner in a Team",
            answer: "The Product Owner defines the product vision, prioritizes backlog items, and ensures the team delivers maximum value.",
            example: "A Product Owner works closely with customers to refine requirements and prioritize high-impact features."
        },
        {
            category: "Teams",
            question: "Delegation in Team Management",
            answer: "The process of assigning tasks to team members based on their expertise and workload.",
            example: "A team lead assigns UI testing tasks to a junior tester while focusing on more complex test automation."
        },
        {
            category: "Teams",
            question: "Diversity and Inclusion in Teams",
            answer: "The practice of creating a team culture that values diverse perspectives, backgrounds, and experiences.",
            example: "A global project team ensures meeting schedules accommodate different time zones and cultural holidays."
        },
        {
            category: "Teams",
            question: "Burnout Prevention in Teams",
            answer: "Strategies to prevent team exhaustion by balancing workloads, offering breaks, and promoting well-being.",
            example: "A project manager introduces 'No-Meeting Fridays' to reduce Zoom fatigue for remote employees."
        },
        {
            category: "Teams",
            question: "Coaching and Mentoring Team Members",
            answer: "The process of guiding team members to enhance their skills, confidence, and professional growth.",
            example: "A senior developer mentors junior programmers by conducting weekly coding review sessions."
        },
        {
            category: "Teams",
            question: "Recognition and Reward Systems",
            answer: "Methods for acknowledging and rewarding team members' contributions to maintain motivation.",
            example: "A company introduces a quarterly 'Team MVP' award to recognize high-performing employees."
        },
        {
            category: "Teams",
            question: "Lessons Learned from Team Performance",
            answer: "A retrospective evaluation of what worked well and what needs improvement in team collaboration.",
            example: "After a major product launch, the team documents lessons about improving cross-departmental communication."
        },
        {
            category: "Development Approach and Life Cycle",
            question: "Development Approach and Life Cycle Performance Domain",
            answer: "The domain that focuses on choosing and managing the project life cycle and development approach to align with project goals.",
            example: "A project manager selects an Agile development approach for a rapidly evolving SaaS platform to allow continuous iterations."
        },
        {
            category: "Development Approach and Life Cycle",
            question: "Project Life Cycle",
            answer: "The phases a project goes through from initiation to closure, regardless of the development approach.",
            example: "A traditional construction project follows a linear life cycle from design, planning, construction, and handover."
        },
        {
            category: "Development Approach and Life Cycle",
            question: "Predictive (Waterfall) Life Cycle",
            answer: "A structured, sequential development approach where phases are planned upfront and completed one at a time.",
            example: "A government infrastructure project follows a predictive life cycle because requirements are well-defined and changes are costly."
        },
        {
            category: "Development Approach and Life Cycle",
            question: "Iterative Life Cycle",
            answer: "A development approach where the project progresses through repeated cycles, refining the solution with each iteration.",
            example: "A software company develops an LMS in multiple iterations, improving usability based on user feedback before final release."
        },
        {
            category: "Development Approach and Life Cycle",
            question: "Incremental Life Cycle",
            answer: "A project development approach where the product is delivered in small usable increments rather than as a whole.",
            example: "A mobile banking app releases basic functionalities first and gradually adds more features like bill payments and budgeting tools."
        },
        {
            category: "Development Approach and Life Cycle",
            question: "Adaptive (Agile) Life Cycle",
            answer: "A flexible, iterative approach that allows teams to respond to changes and continuously refine deliverables.",
            example: "A startup uses Agile sprints to build a prototype, gather user feedback, and adjust features accordingly."
        },
        {
            category: "Development Approach and Life Cycle",
            question: "Hybrid Life Cycle",
            answer: "A combination of predictive and adaptive methodologies, balancing structured planning with flexibility.",
            example: "A company uses a predictive approach for hardware design and an Agile approach for software development in a smart home device project."
        },
        {
            category: "Development Approach and Life Cycle",
            question: "Agile Development Approach",
            answer: "A framework that emphasizes collaboration, customer feedback, and incremental delivery over rigid planning.",
            example: "A software team uses Agile to develop an e-commerce platform, releasing new features in biweekly sprints."
        },
        {
            category: "Development Approach and Life Cycle",
            question: "Scrum Framework",
            answer: "An Agile methodology that focuses on sprints, iterative development, and daily stand-ups.",
            example: "A project team works in two-week sprints to build and test new LMS features."
        },
        {
            category: "Development Approach and Life Cycle",
            question: "Kanban Framework",
            answer: "A visual workflow management system that focuses on limiting work in progress (WIP) and continuous delivery.",
            example: "A content development team uses a Kanban board to track video scripting, editing, and publishing stages."
        },
        {
            category: "Development Approach and Life Cycle",
            question: "Lean Development",
            answer: "A development approach focused on maximizing customer value while minimizing waste.",
            example: "A startup develops a minimum viable product (MVP) to test demand before full-scale production."
        },
        {
            category: "Development Approach and Life Cycle",
            question: "DevOps Approach",
            answer: "A combination of development and operations that integrates continuous development, testing, and deployment.",
            example: "A software team automates testing and deployment to release updates faster."
        },
        {
            category: "Development Approach and Life Cycle",
            question: "Phased Development",
            answer: "A life cycle where a project is divided into distinct, manageable phases.",
            example: "A university builds an online learning platform in phases—starting with user registration, then adding course modules, and finally launching live classes."
        },
        {
            category: "Development Approach and Life Cycle",
            question: "Spiral Model",
            answer: "A risk-driven model that combines iterative and waterfall approaches, emphasizing risk analysis.",
            example: "A defense contractor uses the Spiral Model to develop secure communication systems, ensuring thorough testing at each iteration."
        },
        {
            category: "Development Approach and Life Cycle",
            question: "V-Model (Verification & Validation Model)",
            answer: "A development approach that ensures each development phase has a corresponding testing phase.",
            example: "A pharmaceutical company uses the V-Model to validate software for drug trials, ensuring regulatory compliance."
        },
        {
            category: "Development Approach and Life Cycle",
            question: "Minimum Viable Product (MVP)",
            answer: "A basic version of a product that includes only essential features to test market viability.",
            example: "A team launches an MVP of an AI-driven tutoring platform with just one subject before scaling to multiple topics."
        },
        {
            category: "Development Approach and Life Cycle",
            question: "Rapid Application Development (RAD)",
            answer: "A methodology that prioritizes quick prototyping and user feedback over extensive planning.",
            example: "A mobile app is developed using RAD, allowing early adopters to test features before full deployment."
        },
        {
            category: "Development Approach and Life Cycle",
            question: "Test-Driven Development (TDD)",
            answer: "A software development approach where tests are written before the actual code to ensure correctness.",
            example: "Developers write test cases for an LMS login feature before coding the authentication system."
        },
        {
            category: "Development Approach and Life Cycle",
            question: "Continuous Integration / Continuous Deployment (CI/CD)",
            answer: "A DevOps practice that automates code integration, testing, and deployment for faster releases.",
            example: "A company uses CI/CD pipelines to push LMS updates to production without manual intervention."
        },
        {
            category: "Development Approach and Life Cycle",
            question: "Gated Development Process",
            answer: "A project life cycle where each phase must be reviewed and approved before moving forward.",
            example: "A government IT project requires formal approvals before transitioning from the design phase to development."
        },
        {
            category: "Development Approach and Life Cycle",
            question: "Agile Release Train (ART)",
            answer: "A concept in SAFe (Scaled Agile Framework) where teams align their deliverables into a synchronized release schedule.",
            example: "A large software company coordinates multiple Agile teams to release updates every quarter in a structured manner."
        },
        {
            category: "Development Approach and Life Cycle",
            question: "Feature-Driven Development (FDD)",
            answer: "An Agile methodology that focuses on designing and building project features in small iterations.",
            example: "An e-learning app prioritizes features like quiz creation, progress tracking, and user analytics based on student demand."
        },
        {
            category: "Development Approach and Life Cycle",
            question: "Adaptive Planning",
            answer: "The ability to modify plans dynamically based on changing project needs and stakeholder feedback.",
            example: "A project team adjusts its sprint backlog mid-cycle after receiving critical user feedback."
        },
        {
            category: "Development Approach and Life Cycle",
            question: "Value-Driven Development",
            answer: "A development strategy that prioritizes high-value features to maximize business impact.",
            example: "A startup focuses on adding AI-based personalized learning before cosmetic UI changes."
        },
        {
            category: "Planning",
            question: "Planning Performance Domain",
            answer: "A domain that focuses on defining project scope, goals, tasks, and dependencies to guide execution and success.",
            example: "A project manager develops a detailed roadmap for an LMS development project, defining milestones and resource allocation."
        },
        {
            category: "Planning",
            question: "Project Planning",
            answer: "The process of establishing the project's objectives, scope, schedule, budget, and risks before execution.",
            example: "A startup plans feature releases for an AI-powered learning app over six months."
        },
        {
            category: "Planning",
            question: "Rolling Wave Planning",
            answer: "A technique where near-term work is planned in detail, while future work is planned at a higher level.",
            example: "A software team details the next two sprints but leaves later releases broadly defined until more data is available."
        },
        {
            category: "Planning",
            question: "Progressive Elaboration",
            answer: "A planning technique where details are refined and added as the project progresses.",
            example: "A content development team starts with a broad outline and refines the course structure as more research is conducted."
        },
        {
            category: "Planning",
            question: "Integrated Planning",
            answer: "Ensuring that all project plans (scope, schedule, cost, quality, risk, etc.) are aligned and interdependent.",
            example: "A project manager ensures that changes to the project scope are reflected in the cost and schedule baselines."
        },
        {
            category: "Planning",
            question: "Scope Planning",
            answer: "The process of defining what is included in the project and what is excluded.",
            example: "A university defines that an LMS upgrade includes new discussion forums but not AI-driven grading."
        },
        {
            category: "Planning",
            question: "Work Breakdown Structure (WBS)",
            answer: "A hierarchical decomposition of project deliverables into smaller, manageable tasks.",
            example: "An LMS project’s WBS breaks work into front-end design, back-end development, testing, and deployment."
        },
        {
            category: "Planning",
            question: "WBS Dictionary",
            answer: "A document that provides detailed descriptions of each WBS component, including work packages, responsibilities, and acceptance criteria.",
            example: "The WBS dictionary entry for 'User Authentication Module' includes login features, security measures, and testing criteria."
        },
        {
            category: "Planning",
            question: "Activity Sequencing",
            answer: "The process of arranging project tasks in logical order based on dependencies.",
            example: "A project team must complete course content creation before video production starts."
        },
        {
            category: "Planning",
            question: "Schedule Baseline",
            answer: "The approved project timeline against which progress is measured.",
            example: "A project sets a schedule baseline with an LMS launch date of August 1, 2025."
        },
        {
            category: "Planning",
            question: "Cost Baseline",
            answer: "The approved budget estimate for the project, including contingency reserves.",
            example: "An EdTech company allocates $100,000 for LMS development, covering salaries, cloud hosting, and marketing."
        },
        {
            category: "Planning",
            question: "Resource Planning",
            answer: "The process of identifying and assigning resources (human, material, equipment) for each task.",
            example: "A training program assigns instructors, video editors, and marketing specialists to specific roles."
        },
        {
            category: "Planning",
            question: "Risk Planning",
            answer: "The process of identifying, assessing, and planning responses for project risks.",
            example: "A software development team plans for cybersecurity risks by implementing encryption and backups."
        },
        {
            category: "Planning",
            question: "Change Management Planning",
            answer: "Establishing a structured process to handle project scope, schedule, or budget changes.",
            example: "A project defines that all scope changes must go through a formal approval process involving stakeholders."
        },
        {
            category: "Planning",
            question: "Procurement Planning",
            answer: "The process of determining what goods/services need to be procured and how vendors will be selected.",
            example: "A company plans to outsource LMS hosting services and sets selection criteria based on security, cost, and uptime."
        },
        {
            category: "Planning",
            question: "Communications Planning",
            answer: "Defining who will receive what project information, when, and through what channels.",
            example: "Executives receive monthly progress reports, while the development team holds daily stand-ups."
        },
        {
            category: "Planning",
            question: "Stakeholder Engagement Planning",
            answer: "The process of determining how stakeholders will be engaged throughout the project lifecycle.",
            example: "A stakeholder engagement plan for an LMS project includes regular updates to university leadership and faculty members."
        },
        {
            category: "Planning",
            question: "Quality Planning",
            answer: "The process of setting quality objectives, standards, and control measures to ensure deliverables meet expectations.",
            example: "An LMS team defines video resolution standards and content accuracy metrics to ensure course quality."
        },
        {
            category: "Planning",
            question: "Assumption Log",
            answer: "A document tracking assumptions about project resources, dependencies, and constraints.",
            example: "A company assumes that students will access an LMS primarily from mobile devices and designs accordingly."
        },
        {
            category: "Planning",
            question: "Lessons Learned Planning",
            answer: "A structured approach for capturing insights and best practices throughout the project for future reference.",
            example: "A project team documents lessons learned from an LMS pilot phase to improve the full-scale rollout."
        },
        {
            category: "Project Work",
            question: "Project Work Performance Domain",
            answer: "The domain that focuses on executing and managing project tasks, deliverables, and activities to achieve objectives.",
            example: "A project manager ensures that the development team delivers each sprint's features on schedule for an LMS project."
        },
        {
            category: "Project Work",
            question: "Direct and Manage Project Work",
            answer: "The process of coordinating people, resources, and tasks to complete project deliverables.",
            example: "A team launches a new mobile app version after completing design, development, and testing phases."
        },
        {
            category: "Project Work",
            question: "Deliverables",
            answer: "Any output or product resulting from project activities, including reports, software, and documentation.",
            example: "A completed mock exam module for an LMS is a deliverable that students can use to practice."
        },
        {
            category: "Project Work",
            question: "Manage Project Knowledge",
            answer: "The process of capturing, sharing, and applying project knowledge to improve performance.",
            example: "A project team documents lessons learned from previous e-learning platform launches to avoid repeating mistakes."
        },
        {
            category: "Project Work",
            question: "Work Performance Data",
            answer: "Raw project data related to task completion, resource usage, and performance metrics.",
            example: "A project dashboard shows task completion rates, budget usage, and risk status."
        },
        {
            category: "Project Work",
            question: "Work Performance Information",
            answer: "Analyzed project data that provides meaningful insights into project progress.",
            example: "A cost performance index (CPI) shows that a project is operating under budget."
        },
        {
            category: "Project Work",
            question: "Issue Log",
            answer: "A document that tracks project issues, their impact, assigned owners, and resolution status.",
            example: "A project team logs a recurring bug in the LMS and assigns developers to resolve it in the next sprint."
        },
        {
            category: "Project Work",
            question: "Change Requests",
            answer: "Formal proposals to modify project scope, schedule, budget, or deliverables.",
            example: "A client requests adding AI-based personalized quizzes to an existing LMS, requiring scope change approval."
        },
        {
            category: "Project Work",
            question: "Corrective Action",
            answer: "A response to bring the project back in alignment with the plan after a deviation occurs.",
            example: "A project manager assigns additional developers to meet a tight deadline after a delay in the previous phase."
        },
        {
            category: "Project Work",
            question: "Preventive Action",
            answer: "A proactive step taken to prevent future project risks or issues from occurring.",
            example: "A development team automates code testing to prevent manual testing delays in software projects."
        },
        {
            category: "Project Work",
            question: "Defect Repair",
            answer: "A process of correcting defects in deliverables to meet quality requirements.",
            example: "A project team fixes a security vulnerability in the LMS before its final release."
        },
        {
            category: "Project Work",
            question: "Task Management",
            answer: "The process of assigning, tracking, and completing project tasks efficiently.",
            example: "A project manager uses JIRA to track coding tasks assigned to developers."
        },
        {
            category: "Project Work",
            question: "Project Constraints",
            answer: "Limitations such as scope, schedule, budget, resources, or quality that affect project execution.",
            example: "A team must complete course development within three months due to a strict academic calendar."
        },
        {
            category: "Project Work",
            question: "Project Dependencies",
            answer: "The relationships between tasks where one task must be completed before another begins.",
            example: "In a project, course content creation must be finished before video production can start."
        },
        {
            category: "Project Work",
            question: "Resource Allocation",
            answer: "The process of assigning available resources to project tasks based on priorities.",
            example: "A company assigns its best instructional designers to develop high-priority certification courses first."
        },
        {
            category: "Project Work",
            question: "Managing Conflicts in Project Work",
            answer: "Techniques to resolve disagreements between team members to maintain productivity.",
            example: "A project manager mediates a conflict between designers and developers regarding UI changes."
        },
        {
            category: "Project Work",
            question: "Daily Stand-Ups (Agile Projects)",
            answer: "Short, daily meetings where the team discusses progress, blockers, and upcoming tasks.",
            example: "A development team shares updates in a 15-minute stand-up meeting before starting their workday."
        },
        {
            category: "Project Work",
            question: "Monitor and Control Project Work",
            answer: "The process of tracking project progress and making adjustments to keep it aligned with objectives.",
            example: "A project team reviews earned value metrics to assess whether they are ahead or behind schedule."
        },
        {
            category: "Project Work",
            question: "Project Work Reviews",
            answer: "Regular assessments of project work to ensure tasks are on track and meet quality standards.",
            example: "A team holds biweekly sprint reviews to evaluate completed tasks and adjust priorities."
        },
        {
            category: "Project Work",
            question: "Measuring Team Performance",
            answer: "Evaluating the efficiency and effectiveness of the project team using key metrics.",
            example: "A Scrum team measures sprint velocity to track progress and identify improvement areas."
        },
        {
            category: "Project Work",
            question: "Project Governance",
            answer: "The framework that ensures project decisions align with organizational goals and policies.",
            example: "A company establishes a governance committee to oversee high-risk IT projects."
        },
        {
            category: "Project Work",
            question: "Managing External Vendors in Project Work",
            answer: "The process of coordinating third-party suppliers and ensuring contract compliance.",
            example: "A university monitors an LMS vendor’s performance against agreed-upon service-level agreements (SLAs)."
        },
        {
            category: "Project Work",
            question: "Key Performance Indicators (KPIs) for Project Work",
            answer: "Metrics used to track project success, such as schedule adherence, cost efficiency, and quality compliance.",
            example: "A KPI for a training project tracks student enrollment rates after a course launch."
        },
        {
            category: "Project Work",
            question: "Closing Out Work Packages",
            answer: "The process of formally marking project tasks as complete before moving to the next phase.",
            example: "A project team finalizes content development before beginning course marketing."
        },
        {
            category: "Delivery",
            question: "Delivery Performance Domain",
            answer: "A domain that focuses on ensuring that the project produces value and delivers intended outcomes.",
            example: "A project team delivers a fully functional LMS platform, ensuring it meets student and instructor needs."
        },
        {
            category: "Delivery",
            question: "Deliverables",
            answer: "The products, services, or results produced by the project to fulfill objectives.",
            example: "A certification training course, including video lessons, quizzes, and a final assessment, is a deliverable."
        },
        {
            category: "Delivery",
            question: "Value Delivery",
            answer: "Ensuring that the project delivers tangible benefits and meets stakeholder expectations.",
            example: "A new LMS system reduces course enrollment time by 50%, increasing user satisfaction."
        },
        {
            category: "Delivery",
            question: "Business Value",
            answer: "The measurable benefits a project brings to an organization, such as revenue growth, cost savings, or process improvements.",
            example: "An online learning platform increases customer retention and subscription revenue by 20%."
        },
        {
            category: "Delivery",
            question: "Minimum Viable Product (MVP)",
            answer: "A basic version of a product with essential features used to test market demand before full development.",
            example: "A startup launches an MVP with only key course features before adding AI-powered recommendations."
        },
        {
            category: "Delivery",
            question: "Incremental Delivery",
            answer: "A strategy where parts of the project are delivered in small, usable increments rather than all at once.",
            example: "A development team first releases basic quiz features, then adds analytics and gamification in later updates."
        },
        {
            category: "Delivery",
            question: "Continuous Delivery",
            answer: "A software development practice where updates are automatically built, tested, and released to production frequently.",
            example: "A cloud-based LMS deploys bug fixes and new features automatically every two weeks."
        },
        {
            category: "Delivery",
            question: "Delivery Metrics",
            answer: "Measures used to assess project outcomes and performance, such as speed, quality, and stakeholder satisfaction.",
            example: "A KPI for an LMS launch tracks the number of new users acquired within the first three months."
        },
        {
            category: "Delivery",
            question: "Verification vs. Validation",
            answer: "Verification ensures the product meets design specifications, while validation ensures the product meets user needs and expectations.",
            example: "A development team verifies that all LMS features function correctly and validates them by testing with real users."
        },
        {
            category: "Delivery",
            question: "User Acceptance Testing (UAT)",
            answer: "The final phase of testing where end users validate that the deliverable meets their requirements.",
            example: "Before launching a new mock exam system, students test it to ensure navigation and scoring are intuitive."
        },
        {
            category: "Delivery",
            question: "Product Backlog (Agile Delivery)",
            answer: "A prioritized list of features, tasks, and enhancements to be delivered in an Agile project.",
            example: "The backlog for an LMS includes new integrations like live chat, personalized study plans, and AI tutoring."
        },
        {
            category: "Delivery",
            question: "Definition of Done (DoD)",
            answer: "A set of criteria that must be met for a deliverable to be considered complete.",
            example: "A project team defines DoD for an LMS module as 'tested, documented, and approved by the product owner'."
        },
        {
            category: "Delivery",
            question: "Product Roadmap",
            answer: "A high-level visual summary that outlines product development and release plans.",
            example: "A startup maps out LMS updates for the next year, including AI recommendations and new certification programs."
        },
        {
            category: "Delivery",
            question: "Agile Release Planning",
            answer: "A strategy that determines when and how new features or product versions will be delivered in iterations.",
            example: "A development team plans releases for an online course platform every quarter based on feature readiness."
        },
        {
            category: "Delivery",
            question: "Service-Level Agreement (SLA)",
            answer: "A contract that defines the expected level of service between a provider and a customer.",
            example: "An LMS vendor guarantees 99.9% uptime and 24/7 customer support in its SLA."
        },
        {
            category: "Delivery",
            question: "Customer Feedback Loops",
            answer: "A process where user input is continuously collected and used to improve product features.",
            example: "A project team monitors student reviews and adjusts LMS features to improve navigation and engagement."
        },
        {
            category: "Delivery",
            question: "Customer Journey Mapping",
            answer: "A visual representation of how users interact with a product from start to finish.",
            example: "A UX designer maps the student journey from course registration to certification completion."
        },
        {
            category: "Delivery",
            question: "Risk-Based Delivery Planning",
            answer: "A strategy where higher-risk components are addressed first to minimize potential project failures.",
            example: "A cybersecurity training platform prioritizes implementing data encryption before expanding content modules."
        },
        {
            category: "Delivery",
            question: "Handover and Transition",
            answer: "The process of transferring the project deliverables to the operational or customer team.",
            example: "A project team hands over an LMS to the IT department for ongoing maintenance after deployment."
        },
        {
            category: "Delivery",
            question: "Benefit Realization",
            answer: "Ensuring that the expected project benefits are achieved and sustained post-delivery.",
            example: "A company measures whether an LMS improves employee training completion rates as expected."
        },
        {
            category: "Delivery",
            question: "Measuring Delivery Success",
            answer: "Evaluating project success based on on-time delivery, budget adherence, user adoption, and satisfaction metrics.",
            example: "A project team tracks active users and dropout rates to assess the impact of an LMS upgrade."
        },
        {
            category: "Delivery",
            question: "Lessons Learned from Delivery",
            answer: "Documenting insights about what worked well and what could be improved in future project deliveries.",
            example: "A development team notes that early stakeholder engagement helped refine LMS features before launch."
        },
        {
            category: "Measurement",
            question: "Measurement Performance Domain",
            answer: "The domain that focuses on defining, tracking, and analyzing key project metrics to assess performance and ensure objectives are met.",
            example: "A project manager tracks budget variance and sprint velocity to measure LMS development efficiency."
        },
        {
            category: "Measurement",
            question: "Key Performance Indicators (KPIs)",
            answer: "Measurable values that indicate project success in areas such as time, cost, quality, and customer satisfaction.",
            example: "The KPI for an online course project includes student enrollment numbers and course completion rates."
        },
        {
            category: "Measurement",
            question: "Earned Value Management (EVM)",
            answer: "A technique that compares planned work to actual performance to assess cost and schedule efficiency.",
            example: "If an LMS project is halfway complete but has used 70% of its budget, EVM identifies overspending issues."
        },
        {
            category: "Measurement",
            question: "Schedule Performance Index (SPI)",
            answer: "A ratio that measures schedule efficiency using the formula: SPI = EV / PV (Earned Value / Planned Value).",
            example: "If an SPI is 0.8, the project is behind schedule; if it’s 1.2, it’s ahead."
        },
        {
            category: "Measurement",
            question: "Cost Performance Index (CPI)",
            answer: "A ratio that measures cost efficiency using the formula: CPI = EV / AC (Earned Value / Actual Cost).",
            example: "A CPI of 1.1 means the project is running under budget; a CPI below 1.0 indicates overspending."
        },
        {
            category: "Measurement",
            question: "Planned Value (PV)",
            answer: "The budgeted cost of work scheduled to be completed at a specific time.",
            example: "A project plans to spend $50,000 in the first three months; if actual spending is $60,000, it's over budget."
        },
        {
            category: "Measurement",
            question: "Earned Value (EV)",
            answer: "The budgeted cost of work actually completed at a given time.",
            example: "If an LMS development phase was planned to be 50% complete but only 30% is done, EV is lower than expected."
        },
        {
            category: "Measurement",
            question: "Actual Cost (AC)",
            answer: "The total cost incurred for work performed at a given point in time.",
            example: "A project has an actual cost of $100,000, but only $80,000 worth of work is completed, indicating a budget overrun."
        },
        {
            category: "Measurement",
            question: "Variance Analysis",
            answer: "The process of comparing actual project performance to planned performance to identify deviations.",
            example: "A project team identifies that delays in content production have increased costs, leading to a negative variance."
        },
        {
            category: "Measurement",
            question: "Cost Variance (CV)",
            answer: "A measure of budget performance, calculated as CV = EV - AC.",
            example: "A negative CV means the project is over budget; a positive CV means it's under budget."
        },
        {
            category: "Measurement",
            question: "Schedule Variance (SV)",
            answer: "A measure of schedule performance, calculated as SV = EV - PV.",
            example: "If SV is negative, the project is behind schedule; if positive, it’s ahead."
        },
        {
            category: "Measurement",
            question: "Forecasting (Estimate at Completion - EAC)",
            answer: "A calculation that predicts total project cost based on current performance trends.",
            example: "If an LMS is 60% complete but costs are running 20% higher than expected, EAC projects the final budget."
        },
        {
            category: "Measurement",
            question: "To-Complete Performance Index (TCPI)",
            answer: "A measure of cost efficiency needed to complete the project within budget.",
            example: "If TCPI is above 1.0, the project must improve cost efficiency; if below 1.0, it has extra budget flexibility."
        },
        {
            category: "Measurement",
            question: "Control Charts",
            answer: "A visual tool that monitors process stability and identifies variations over time.",
            example: "A control chart for video production time helps identify inefficiencies in content creation."
        },
        {
            category: "Measurement",
            question: "Trend Analysis",
            answer: "A method of analyzing project data trends to forecast future performance.",
            example: "A project team analyzes cost trends to predict whether the budget will remain stable or increase over time."
        },
        {
            category: "Measurement",
            question: "Root Cause Analysis (RCA)",
            answer: "A technique used to identify the fundamental cause of project performance issues.",
            example: "If an LMS development project is consistently delayed, RCA might reveal that unclear requirements are causing rework."
        },
        {
            category: "Measurement",
            question: "Performance Reviews",
            answer: "Formal evaluations that compare project progress against baseline goals.",
            example: "A monthly performance review assesses whether an e-learning course project is on track."
        },
        {
            category: "Measurement",
            question: "Key Success Factors (KSFs)",
            answer: "The critical elements that determine whether a project meets its objectives.",
            example: "A key success factor for an online certification course is student engagement and completion rates."
        },
        {
            category: "Measurement",
            question: "Customer Satisfaction Metrics",
            answer: "Measures used to gauge stakeholder and customer satisfaction with project outcomes.",
            example: "A Net Promoter Score (NPS) survey determines how likely students are to recommend a new training platform."
        },
        {
            category: "Measurement",
            question: "Benchmarking",
            answer: "The practice of comparing project performance against industry standards or past projects.",
            example: "A company compares its LMS launch metrics against competitors to evaluate market success."
        },
        {
            category: "Measurement",
            question: "Lessons Learned Documentation",
            answer: "A record of project insights, best practices, and areas for improvement for future projects.",
            example: "A team documents challenges faced during LMS testing to avoid similar issues in future upgrades."
        },
        {
            category: "Measurement",
            question: "Balanced Scorecard",
            answer: "A strategic tool that measures performance across financial, customer, internal processes, and learning perspectives.",
            example: "A university tracks student engagement, course completion rates, and platform usability in its LMS."
        },
        {
            category: "Measurement",
            question: "Audit and Compliance Reviews",
            answer: "A formal evaluation to ensure that project processes meet legal, regulatory, or internal standards.",
            example: "A cybersecurity audit ensures that an LMS complies with GDPR and data protection laws."
        },
        {
            category: "Measurement",
            question: "Measuring Benefits Realization",
            answer: "The process of assessing whether the expected project benefits are being achieved.",
            example: "A company measures whether employee training courses have increased productivity as intended."
        },
        {
            category: "Uncertainty",
            question: "Uncertainty Performance Domain",
            answer: "A domain that focuses on identifying, managing, and adapting to uncertainties that impact project outcomes.",
            example: "A project team adjusts its development approach when new government regulations impact e-learning platforms."
        },
        {
            category: "Uncertainty",
            question: "Complexity in Uncertainty Management",
            answer: "Complex projects have high uncertainty due to multiple interdependent factors, evolving requirements, and stakeholder influence.",
            example: "A smart education system integrating AI and IoT sensors faces complexity due to new technology and data privacy laws."
        },
        {
            category: "Uncertainty",
            question: "Emergent Risks (Unknown-Unknowns)",
            answer: "Risks that cannot be predicted in advance and emerge unexpectedly.",
            example: "A pandemic forces an EdTech company to rapidly shift from in-person training to virtual learning."
        },
        {
            category: "Uncertainty",
            question: "Ambiguity in Projects",
            answer: "Situations where lack of information makes it difficult to define problems and solutions clearly.",
            example: "A company launching a new LMS in a foreign market faces ambiguity about cultural adoption and local compliance."
        },
        {
            category: "Uncertainty",
            question: "Volatility in Project Environments",
            answer: "Rapid changes in market trends, technology, or external factors that affect project execution.",
            example: "An online learning startup faces volatility as new competitors enter the market with innovative AI-driven features."
        },
        {
            category: "Uncertainty",
            question: "Adaptive Project Approaches",
            answer: "Strategies that allow projects to remain flexible and responsive to changes in uncertain environments.",
            example: "A software development team uses Agile sprints to quickly adjust course content based on student feedback."
        },
        {
            category: "Uncertainty",
            question: "Sensemaking in Uncertainty",
            answer: "The process of interpreting complex or ambiguous situations to guide decision-making.",
            example: "A project manager gathers expert opinions to understand how new industry regulations affect project scope."
        },
        {
            category: "Uncertainty",
            question: "Scenario Planning",
            answer: "A method of anticipating possible future situations and preparing strategies to address them.",
            example: "A university develops multiple scenarios for online vs. hybrid learning depending on COVID-19 developments."
        },
        {
            category: "Uncertainty",
            question: "Exploratory Testing",
            answer: "A technique that focuses on testing unknown conditions rather than following predefined test cases.",
            example: "An LMS team explores how the platform handles unexpected user behaviors in different regions."
        },
        {
            category: "Uncertainty",
            question: "Agility in Decision-Making",
            answer: "The ability to quickly make informed decisions in response to changing conditions.",
            example: "A company switches from a fixed LMS provider to a cloud-based solution after experiencing scalability issues."
        },
        {
            category: "Uncertainty",
            question: "Resilience Planning",
            answer: "Developing strategies to ensure a project can recover from unexpected disruptions.",
            example: "An LMS provider implements redundant cloud servers to ensure uptime in case of outages."
        },
        {
            category: "Uncertainty",
            question: "Real Options Thinking",
            answer: "A decision-making approach that allows for flexibility by keeping multiple options open.",
            example: "A project secures multiple vendor contracts to maintain supply chain flexibility during hardware shortages."
        },
        {
            category: "Uncertainty",
            question: "Incremental Development to Reduce Uncertainty",
            answer: "Delivering projects in small increments to test assumptions and reduce uncertainty over time.",
            example: "An LMS launches a beta version with limited features to gather user feedback before scaling."
        },
        {
            category: "Uncertainty",
            question: "Knowledge-Based Risk Management",
            answer: "Using historical data, expert opinions, and simulations to address uncertainty.",
            example: "A cybersecurity team analyzes past data breaches to implement proactive security measures."
        },
        {
            category: "Uncertainty",
            question: "Pivoting in Response to Uncertainty",
            answer: "Changing project direction based on new information or unexpected developments.",
            example: "A company shifts from paid subscriptions to freemium pricing after market analysis shows resistance to upfront costs."
        },
        {
            category: "Uncertainty",
            question: "Minimum Viable Experiment (MVE)",
            answer: "Running small-scale tests to validate assumptions and minimize uncertainty.",
            example: "A project team tests an AI-powered chatbot with a small user group before full implementation."
        },
        {
            category: "Uncertainty",
            question: "Risk-Adjusted Backlog (Agile Projects)",
            answer: "Prioritizing backlog items based on risk levels and uncertainty factors.",
            example: "A development team tackles high-risk LMS security features first to ensure compliance."
        },
        {
            category: "Uncertainty",
            question: "Uncertainty Reduction Through Prototyping",
            answer: "Creating prototypes to test feasibility and refine solutions in ambiguous situations.",
            example: "An EdTech firm builds a prototype AI tutor to evaluate its effectiveness before full-scale investment."
        },
        {
            category: "Uncertainty",
            question: "Early Warning Systems for Uncertainty",
            answer: "Setting up indicators that detect potential disruptions early to enable proactive action.",
            example: "A company monitors student engagement metrics to detect early signs of LMS adoption failure."
        },
        {
            category: "Uncertainty",
            question: "Emergent Requirements Handling",
            answer: "Managing requirements that become clear only as the project evolves.",
            example: "A mobile learning app adds offline mode after realizing many users have unreliable internet access."
        },
        {
            category: "Uncertainty",
            question: "Horizon Scanning",
            answer: "A proactive strategy that monitors future trends and external factors that may impact the project.",
            example: "A digital learning provider tracks AI advancements to stay competitive in personalized education."
        },
        {
            category: "Uncertainty",
            question: "Probabilistic Forecasting",
            answer: "Using probability models to predict different possible outcomes under uncertainty.",
            example: "A company uses Monte Carlo simulations to estimate project completion timelines under various risk scenarios."
        },
        {
            category: "Uncertainty",
            question: "Lessons Learned in Uncertainty Management",
            answer: "A process of documenting insights from handling uncertainty for future projects.",
            example: "A team records how rapid user feedback loops helped improve a new exam feature in their LMS."
        }
    ]
  
  export default PerformanceDomainData;
  